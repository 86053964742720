import {useState, useEffect, useMemo, useRef} from "react";

import {useSelector, useDispatch} from "react-redux";
import { useNavigate} from 'react-router-dom'

import PageHeader from "../components/PageHeader";

import { getbalance, memberchangpass, getwebinfo } from "../store/user";

import { Spinner } from "react-bootstrap";
import { WEBTOKEN } from "../store/config";
function Affiliate() {
   
    const { isLoadingBtn:isLoading, item, showPass, reloadPass, webinfo } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getbalance({}))
        dispatch(getwebinfo({}))
    },[])
    const navigate = useNavigate()

    const txtpassword1 = useRef(null)
    const txtpassword2 = useRef(null)
    const txtpassword = useRef(null)

    const signup = () => {
        const password1 = txtpassword1.current.value;
        const password2 = txtpassword2.current.value;
        const password = txtpassword.current.value;
      
       
        if(!password){
            txtpassword.current.focus()
        } else if(!password1 && password2){
            txtpassword2.current.focus()

        }else if(!password1){
            txtpassword1.current.focus()
        }else if(!password2){
            txtpassword2.current.focus()
        } else {
            const data = {
                password,
                newpassword:password1,
                webtoken:WEBTOKEN,
            }
            dispatch(memberchangpass(data));
        }
        
    }

    useMemo(()=> {
        if(reloadPass === 1){
            const timer = setTimeout(() => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('username')
                window.location = '/'
              }, 3000);
              return () => clearTimeout(timer);
            
        }
    } , [reloadPass])

    return (<div className={'container affiliate'}>
        <div className={'row justify-content-center min-vh-100 py-4'}>
            <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                {/* page title */}
                <PageHeader logo/>
                {/* end page title */}

                {/* affiliate id */}
                <div className={'card card-green mb-5'}>

              

                    <div className={'card-body'}>
                    <div className={'card card-green mt-4 py-3'}>
                        <div className={'card-body'}>
                            <h3 className={'text-center'}>
                            ຕິດຕໍ່ ສອບຖາມ
                            </h3>
                           
                            <div className={'text-center mb-4 text-secondary'}>
                                
                                
ຜ່ານ 3 ຊ່ອງທາງນີ້ <br/>
                            </div>

                            <div className={'d-flex justify-content-center gap-2 mb-2 text-center'}>
                            <div className="pt-3">
             <a href={webinfo?.facebookurl} target="bank"><img src="/images/facebook.png" height={70} ></img></a> 
             <a href={webinfo?.facebookurl} target="bank"> <div className="btnsubmit44" >
             ເຟສບຸກ : {webinfo?.facebookid}
            </div>
            </a>
          </div>
                            </div>

                            <div className={'d-flex justify-content-center gap-2 mb-2 text-center'}>
                            <div className="pt-4">
          <a href={`https://wa.me/${webinfo?.whatappid}`} target="bank"> <img src="/images/whatsapp-logo.png" height={80} ></img></a>
          <a href={`https://wa.me/${webinfo?.whatappid}`} target="bank"><div className="btnsubmit55" >
          ວັອດແອັບ : {webinfo?.whatappid}
            </div>
            </a>
          </div>
                            </div>
                            <div className={'d-flex justify-content-center  gap-2 mb-2 text-center'}>
                            <div className="pt-4">
          <a href={webinfo?.lineurl} target="bank"> <img src="/images/line-logo2.png" height={80} ></img></a>
          <a href={webinfo?.lineurl} target="bank"><div className="btnsubmit55" >
          ລາຍ : {webinfo?.lineid}
            </div>
            </a>
          </div>
                        </div>
                        
                               
                           

                         
                        </div>
                    </div>
                    </div>
                </div>
                {/* end affiliate id */}

              
               


               
            </div>
        </div>
        <div className="linefixed" onClick={()=>navigate('/contacts')}><img width="150" height="231" src="/images/thaiman.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/thaiman.png 150w, /images/thaiman.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
    </div>);
}

export default Affiliate;
