import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    list: [
        {id: 1, image: "01", name: "ปลาน้อย", number: '01'},
        {id: 2, image: "02", name: "หอย", number: '02'},
        {id: 3, image: "03", name: "ห่าน", number: '03'},
        {id: 4, image: "04", name: "นกยูง", number: '04'},
        {id: 5, image: "05", name: "สิงโต", number: '05'},
        {id: 6, image: "06", name: "เสือ", number: '06'},
        {id: 7, image: "07", name: "หมู", number: '07'},
        {id: 8, image: "08", name: "กระต่าย", number: '08'},
        {id: 9, image: "09", name: "ควาย", number: '09'},
        {id: 10, image: "10", name: "นาก", number: '10'},
        {id: 11, image: "11", name: "หมา", number: '11'},
        {id: 12, image: "12", name: "ม้า", number: '12'},
        {id: 13, image: "13", name: "ช้าง", number: '13'},
        {id: 14, image: "14", name: "แมวบ้าน", number: '14'},
        {id: 15, image: "15", name: "หนู", number: '15'},
        {id: 16, image: "16", name: "ผึ้ง", number: '16'},
        {id: 17, image: "17", name: "กระยาง", number: '17'},
        {id: 18, image: "18", name: "แมวป่า", number: '18'},
        {id: 19, image: "19", name: "ผีเสื้อ", number: '19'},
        {id: 20, image: "20", name: "ตะขาบ", number: '20'},
        {id: 21, image: "21", name: "นางแอ่น", number: '21'},
        {id: 22, image: "22", name: "พิราบ", number: '22'},
        {id: 23, image: "23", name: "ลิง", number: '23'},
        {id: 24, image: "24", name: "กบ", number: '24'},
        {id: 25, image: "25", name: "เหยี่ยว", number: '25'},
        {id: 26, image: "26", name: "มังกร", number: '26'},
        {id: 27, image: "27", name: "เต่า", number: '27'},
        {id: 28, image: "28", name: "ไก่", number: '28'},
        {id: 29, image: "29", name: "ปลาไหล", number: '29'},
        {id: 30, image: "30", name: "ฉลาม", number: '30'},
        {id: 31, image: "31", name: "กุ้ง", number: '31'},
        {id: 32, image: "32", name: "งู", number: '32'},
        {id: 33, image: "33", name: "แมงมุม", number: '33'},
        {id: 34, image: "34", name: "กวาง", number: '34'},
        {id: 35, image: "35", name: "แพะ", number: '35'},
        {id: 36, image: "36", name: "แพะ", number: '36'},
        {id: 37, image: "37", name: "ลิ่ม", number: '37'},
        {id: 38, image: "38", name: "แม่น", number: '38'},
        {id: 39, image: "39", name: "ปู", number: '39'},
        {id: 40, image: "40", name: "อินทรีย์", number: '40'},
    ],
    cart: [],
    total: 0
}

export const namsudSlice = createSlice({
    name: 'namsud',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            let data = action.payload;
            state.cart.push(data);

            namsudSlice.caseReducers.calculateTotal(state)
        },
        calculateTotal: (state) => {
            let total = 0;
            state.cart.map(data => {
                total += data.amount;
            });
            state.total = total;
        },
        clearCart: (state) => {
            state.cart = [];
            state.total = 0;
        }
    },
})

// Actions
export const {addToCart, clearCart} = namsudSlice.actions;
export default namsudSlice.reducer
