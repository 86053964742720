import {useEffect, useState, useMemo} from "react";
import {Nav, Tab} from "react-bootstrap";
import * as _ from "underscore";
import {numberWithCommas, pad} from "../../utils/math";
import {getBackNumber2, getBackNumber3, swipeFront, swipeBack, nineteenDoor} from "../../utils/tode";
import PageHeader from "../../components/PageHeader";
import {NumericFormat} from "react-number-format";
import { useParams, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { huayrate } from "../../store/user";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CountdownTimer from "./CountdownTimer2";
import Modal from '../../components/Modal'

function StockSelect() {
    const { rateitem:huysubdatanew } = useSelector(state=>state.user)
    const dispatch = useDispatch()
    const { huaysubId } = useParams()
    const navigate = useNavigate()
    // cart
    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);
    
    // mode
    const items = localStorage.getItem('rateitems')
    const obj = JSON.parse(items);
    const rateitem = localStorage.getItem('rateitem')
    const obj2 = JSON.parse(rateitem);
    const [modeList] = useState(obj);
    const [huaysubdata] = useState(obj2);
    const [modeSelected, setModeSelected] = useState([]);

    // number on board
    const [numberList, setNumberList] = useState([]);
    const [numberRangeSelected, setNumberRangeSelected] = useState(0);

    // number type 0 2 3
    const [numberType, setNumberType] = useState(3);

    // total
    const [totalCart, setTotalCart] = useState(0);
    const [total, setTotal] = useState(0);

    const saveHuay = () => {
     
        const itemsall = selected.reduce((result, list) => {
            const data = [...result,...list.result]
            return data
        } ,[])
       
        const checkal = itemsall.filter(x=>parseFloat(x.amount) < 1000)

        if(total >= 1000 && checkal.length ===0) {

            const newitems = JSON.stringify(selected)
            localStorage.setItem('billItems', newitems)
            localStorage.setItem('huaysubId', huaysubId)
            const items = localStorage.getItem('billItems')
            const obj = JSON.parse(items);
           
            navigate(`/lotto/stocks/summary`)
        }else{
            setShow(true)
        }   
      
       
    }

    useEffect(() => {
        // init
        dispatch(huayrate({huaysubId}))
        selectMode(modeList[0]);
        console.log('init')
    }, []);

   

    useEffect(() => {
        drawBoard();
        setTypingNumber("");

        console.log('num type, range change')
    }, [numberRangeSelected, numberType]);

    useEffect(() => {
        // calculate total
        let total = 0;
        let totalCart = 0;
        selected.map(mode => {
            mode.result.map(result => {
                total += Number(result.amount);
                totalCart += 1;
            })
        })
        setTotal(total)
        setTotalCart(totalCart)

        console.log('calculate')
    }, [selected]);

    const drawBoard = () => {
        let list = [];
        let pow = numberType > 0 ? 100 : 10
        let min = numberRangeSelected * pow;
        let max = min + pow;
        for (let i = min; i < max; i++) {
            list.push(pad(i, numberType));
        }

        setNumberList(list);
        console.log('draw board')
    }

    const selectNumber = (number) => {
        // check active mode
        let activeMode = _.filter(modeSelected, function (mode) {
            return mode.typeRun
        });

        if (activeMode.length > 0) {
            // check exist number
            let existNumber = _.some(selected, function (mode) {
                return _.findWhere(mode.result, {number: number});
            });

            if (existNumber) {
                // remove form all mode
                selected.map(mode => {
                    mode.result = _.without(mode.result, _.findWhere(mode.result, {
                        number: number
                    }));
                })
                setSelected([...selected])
                console.log('exist number', number, 'remove from all mode')
            } else {

                // check is back number
                let backNumberMode = _.findWhere(modeSelected, {backNumber: 1});
                if (backNumberMode) {

                    // get back number
                    let numbers = [];
                    if (activeMode[0].numberType === 3) {
                        numbers = getBackNumber3(number);
                    } else if (activeMode[0].numberType === 2) {
                        numbers = getBackNumber2(number);
                    }

                    // add number to all mode
                    activeMode.map(mode => {
                        let obj = _.findWhere(selected, {id: mode.id});

                        // set up obj numbers
                        let objNumbers = [];
                        numbers.map(number => {
                            objNumbers.push({mode: mode.id, 'number': number, amount: mode.minBet})
                        })


                        if (obj) {
                            // add
                            obj.result = [...obj.result, ...objNumbers];
                            obj.result = _.uniq(obj.result, function (list) {
                                return list.number;
                            })
                            setSelected([...selected]);

                        } else {
                            // create
                            setSelected(prevState => ([...prevState, {
                                ...mode,
                                result: _.uniq(objNumbers, function (list) {
                                    return list.number;
                                })
                            }]))
                        }
                    })

                    // check mode type number
                    console.log('add number with back mode', activeMode[0].numberType, numbers);

                } else {
                    // normal mode
                    console.log('add number normal', number);

                    // add number to all mode
                    activeMode.map(mode => {
                        let obj = _.findWhere(selected, {id: mode.id});
                        if (obj) {
                            // add
                            obj.result.push({mode: mode.id, 'number': number, amount: mode.minBet});
                            obj.result = _.uniq(obj.result, function (list) {
                                return list.number;
                            })
                            setSelected([...selected]);
                        } else {
                            // create
                            setSelected(prevState => ([...prevState, {
                                ...mode,
                                result: [{mode: mode.id, 'number': number, amount: mode.minBet}]
                            }]))
                        }
                    })
                }
            }
        } else {
            console.log('please select mode')
        }
    }

    const selectMode = (mode) => {
        let existMode = _.findWhere(modeSelected, {id: mode.id});
        if (existMode) {
            // remove mode
            setModeSelected(_.without(modeSelected, _.findWhere(modeSelected, {
                id: mode.id
            })));
        } else {

            // only 3 digit
            if (mode.numbertype === 3) {
                if (mode.typerun) {
                    // remove back
                    setModeSelected(_.without(modeSelected, _.findWhere(modeSelected, {
                        backNumber: 1,
                        tode: 0
                    })));
                } else {
                    // remove tode
                    setModeSelected(_.without(modeSelected, _.findWhere(modeSelected, {
                        tode: 1
                    })));
                }
            }

            let numType = modeSelected[0] ? modeSelected[0].numberType : null;
            if (mode.numbertype !== numType && modeSelected.length !== 0) {
                // reset if num type diff
                setModeSelected([]);
                setNumberRangeSelected(0)
            }

            // add active mode
            setModeSelected(prevState => [...prevState, {
                id: mode.id,
                numberType: mode.numbertype,
                tode: mode.tode,
                backNumber: mode.backnumber,
                typeRun: mode.typerun,
                maxBet: mode.maxbet,
                minBet: mode.minbet,
                name: mode.name,
                nameTH: mode.nameth,
                rate: mode.rate
            }]);

            // set number type
            setNumberType(mode.numbertype)
        }
    }

    const isActiveMode = (data) => {
        let {id} = data;
        let obj = _.findWhere(modeSelected, {id: id});
        if (obj) {
            return 'active'
        } else {
            return ''
        }
    }

    const isActiveNumberRange = (number) => {
        if (number === numberRangeSelected) {
            return 'active'
        } else {
            return ''
        }
    }

    const isActiveNumber = (number) => {
        let exist = _.some(selected, function (mode) {
            return _.findWhere(mode.result, {number: number});
        });

        if (exist) {
            return 'active'
        } else {
            return ''
        }
    }

    const addSwipeFront = (number) => {
        let numbers = swipeFront(number);
        addNumbers(numbers);
    }

    const addSwipeBack = (number) => {
        let numbers = swipeBack(number);
        addNumbers(numbers);
    }

    const addNineTeenDoor = (number) => {
        let numbers = nineteenDoor(number);
        addNumbers(numbers);
    }

    const addNumbers = (numbers) => {
        // check active mode
        let activeMode = _.filter(modeSelected, function (mode) {
            return mode.typeRun
        });

        if (activeMode.length > 0) {
            // add number to all mode
            activeMode.map(mode => {
                let obj = _.findWhere(selected, {id: mode.id});

                // set up obj numbers
                let objNumbers = [];
                numbers.map(number => {
                    objNumbers.push({mode: mode.id, 'number': number, amount: mode.minBet})
                })

                if (obj) {
                    // add
                    obj.result = [...obj.result, ...objNumbers];
                    obj.result = _.uniq(obj.result, function (list) {
                        return list.number;
                    })
                    setSelected([...selected]);

                } else {
                    // create
                    setSelected(prevState => ([...prevState, {
                        ...mode,
                        result: _.uniq(objNumbers, function (list) {
                            return list.number;
                        })
                    }]))
                }
            })

            // check mode type number
            console.log('add swipe front', numbers);
        } else {
            console.log('please select mode')
        }
    }

    const deleteNumber = (data) => {
        let obj = _.findWhere(selected, {id: data.mode});
        obj.result = _.without(obj.result, _.findWhere(obj.result, {
            number: data.number
        }));
        setSelected([...selected])
    }

    const setAllAmount = (amount) => {
        // set amount all mode
        selected.map(mode => {
            mode.result.map(result => {
                result.amount = amount;
            })
        })
        setSelected([...selected])
    }

    const setAmount = ({id, number, amount}) => {
        // set amount
        let mode = _.findWhere(selected, {id: id});
        let list = _.findWhere(mode.result, {
            number: number
        });
        list.amount = amount ? Number(amount) : 1;
        setSelected([...selected])
    }

    const [typingNumber, setTypingNumber] = useState('');
    const typing = (number) => {
        setTypingNumber(number);

        if (number.length === numberType) {
            // check exist number
            let existNumber = _.some(selected, function (mode) {
                return _.findWhere(mode.result, {number: number});
            });

            if (!existNumber) {
                selectNumber(number);
            }

            // reset form
            setTypingNumber("");
        } else if (numberType === 0) {
            // check exist number
            let existNumber = _.some(selected, function (mode) {
                return _.findWhere(mode.result, {number: number});
            });

            if (!existNumber) {
                selectNumber(number);
            }

            // reset form
            setTypingNumber("");
        }
    }

    const handleKeyDown = (e) => {
        if (isNaN(+e.key)) {
            e.preventDefault();
        }
    };

    const clickNumber = (number) => {
        let numbers = typingNumber + number;
        setTypingNumber(numbers);
        typing(numbers);
    }

    const clearCart = () => {
        Swal.fire({
            html: `<div class=""> ເຈົ້າແນ່ໃຈບໍ່ວ່າຕ້ອງການລົບ ເລກທັງຫມົ ? </div>`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: false,
            focusCancel: false,
            reverseButtons: true,
            confirmButtonText: "ລົບ",
            cancelButtonText: "ປິດ",
            customClass: {
                confirmButton: "bg-danger",
                cancelButton: "bg-secondary"
            }
        }).then(async (result) => {
            if (result.value) {
                setSelected([]);
            }
        })
    }

    return (
        <div className={'container hoon'}>
            <div className={'row justify-content-center min-vh-100 py-4'} data-aos="fade-up">
                <div className={'col-12'}>

                    {/* page title */}
                    <PageHeader logo backPath={'/lotto/stock'}/>
                    {/* end page title */}

                    {/* countdown */}
                    {
                        /*
<h5 className={'mb-3 text-primary text-center'}><i className="far fa-clock me-1"/> 09:09:09</h5>
                        */
                    }
                     <h5 className={'mb-3 text-primary text-center'}><CountdownTimer countdownTimestampMs={huaysubdata?.enddate} /></h5>
                    
                    {/* end countdown */}


                    <div className={'d-flex justify-content-center'}>
                        <div className={'row justify-content-center'} style={{maxWidth: "1200px"}}>
                            <div className={'col-12'}>
                                {/* announcement */}
                                <div className={'text-center'}>
                                                <div className={'xribbonlotto'}>
                                                    ປິດຮັບ {huysubdatanew.endtime}
                                                </div>
                                            </div>
                                <div className={'mb-3 card card-green'}>
                               
                                    <div className={`header position-relative ${huysubdatanew.bghead}`}>

                                        <div className={`ms-2 fw-bold`}>
                                            {huysubdatanew?.name} | ວັນທີ : {huysubdatanew?.startdate}
                                        </div>
                                        <div className={`flags position-absolute ${huysubdatanew?.bgphoto}`}>

                                        </div>
                                    </div>
                                    <div className={'card-body'}>
                                        <img
                                            alt={'announcement'}
                                            height={'20px'}
                                            width={'20px'}
                                            className={'me-2'}
                                            src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/> ສົ່ງຕໍ່ລິ້ງລ້ຳລວຍຂອງເຮົາໃຫ້ໝູ່ສະໝັກຮັບຄອມມິດຊັ້ນຂອງໝູ່
                                        <span className={'text-danger'}> 6% </span> ແລະຄ່າຄອມມິດຊັ້ນຂອງໝູ່
                                        ຂອງໝູ່ອີກ <span
                                        className={'text-danger'}> 1%</span>
                                    </div>
                                </div>
                                {/* end announcement */}
                            </div>

                            <div className={'col-12 mb-3 col-lg-8'}>
                                <Tab.Container defaultActiveKey="self">
                                    <div className={'hoon-tab'}>
                                        <Nav variant="tabs" fill>
                                            <Nav.Item className={'pe-1'}>
                                                <Nav.Link eventKey="self">

                                                    <img className="icon" alt={'menu'}
                                                         src={`${process.env.PUBLIC_URL}/images/icon/tunsamai_gray.png`}/>
                                                    ກົດເລກເອງ

                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className={'ps-1'}>
                                                <Nav.Link eventKey="board">

                                                    <img className="icon" alt={'menu'}
                                                         src={`${process.env.PUBLIC_URL}/images/icon/tunsamai_gray.png`}/>
                                                    ເລືອກຈາກແຜງ

                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>

                                    <div className={'card card-green board'}>
                                        <div className={'card-body'}>
                                            <Tab.Content>

                                                {/* mode */}
                                                <div className={'row row-cols-4 g-1 mb-3'}>
                                                    {modeList.map((mode, index) => {
                                                        return <div key={mode.id}
                                                                    className={`col`}>
                                                            <div
                                                                className={`select-mode type_${mode.numbertype} ${isActiveMode(mode)} `}
                                                                onClick={() => selectMode(mode)}
                                                            >
                                                                <div>
                                                                    {mode.name}
                                                                </div>
                                                                <div>
                                                                    {mode.rate}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    })}
                                                </div>
                                                {/* end mode */}

                                                <Tab.Pane eventKey="self">


                                                    <div className={'w-100 mt-5 mb-4'}>
                                                        <OtpInput
                                                            containerStyle={'justify-content-center'}
                                                            inputStyle={'otp-input'}
                                                            value={typingNumber}
                                                            onChange={typing}
                                                            numInputs={numberType ? numberType : 1}
                                                            renderSeparator={<span className={'otp-space'}/>}
                                                            renderInput={(props) => <input {...props}
                                                                                           inputMode={'numeric'}
                                                                                           onKeyDown={handleKeyDown}/>}
                                                        />
                                                    </div>


                                                    <div className={'d-flex gap-3'}>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(1)}>
                                                            1
                                                        </div>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(2)}>
                                                            2
                                                        </div>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(3)}>
                                                            3
                                                        </div>
                                                        <div className={'numpad bg-danger'}
                                                             onClick={() => setTypingNumber(prevState => prevState.slice(0, -1))}>
                                                            <i className="fas fa-lg fa-backspace"/>
                                                        </div>
                                                    </div>

                                                    <div className={'d-flex gap-3'}>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(4)}>
                                                            4
                                                        </div>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(5)}>
                                                            5
                                                        </div>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(6)}>
                                                            6
                                                        </div>
                                                        <div className={'numpad btn-numpad bg-secondary'}>
                                                        ຍົກເລີກລ່າສຸດ
                                                        </div>
                                                    </div>

                                                    <div className={'d-flex gap-3'}>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(7)}>
                                                            7
                                                        </div>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(8)}>
                                                            8
                                                        </div>
                                                        <div className={'numpad'}
                                                             onClick={() => clickNumber(9)}>
                                                            9
                                                        </div>
                                                        <div className={'numpad btn-numpad bg-black'}
                                                             onClick={() => clearCart()}>
                                                            ລ້າງຂໍ້ມູນ
                                                        </div>
                                                    </div>

                                                    <div className={'d-flex gap-3'}>
                                                        <div className={'numpad btn-numpad bg-secondary'}>
                                                        ສຸ່ມໂຕເລກ
                                                        </div>
                                                        <div className={'col numpad'}
                                                             onClick={() => clickNumber(0)}>
                                                            0
                                                        </div>
                                                        <div className={'col'}>

                                                        </div>
                                                        <div className={'col'}>

                                                        </div>
                                                    </div>


                                                </Tab.Pane>
                                                <Tab.Pane eventKey="board">
                                                    {/* board */}
                                                    {modeSelected.length > 0 && <div>
                                                        {numberType === 3 && <div>
                                                            {/* number range */}
                                                            <div className={'d-flex gap-1 mb-1 justify-content-around'}>
                                                                {
                                                                    [0, 1, 2, 3, 4].map(number => {
                                                                        return <div
                                                                            className={`select-range type_3 w-100 ${isActiveNumberRange(number)}`}
                                                                            key={number}
                                                                            onClick={() => setNumberRangeSelected(number)}>
                                                                            {pad(number * 100, 3)}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>

                                                            <div className={'d-flex gap-1 mb-3 justify-content-around'}>
                                                                {
                                                                    [5, 6, 7, 8, 9].map(number => {
                                                                        return <div
                                                                            className={`select-range  type_3 w-100 ${isActiveNumberRange(number)}`}
                                                                            key={number}
                                                                            onClick={() => setNumberRangeSelected(number)}>
                                                                            {pad(number * 100, 3)}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            {/* end number range*/}

                                                            {/* list 3 tuo */}
                                                            <div className={'grid-container hundred mb-2'}>
                                                                {numberList.map(number => {
                                                                    return <div
                                                                        className={`number-box type_3 ${isActiveNumber(number)}`}
                                                                        onClick={() => selectNumber(number)}
                                                                        key={number}>
                                                                        <div className={'number'}>
                                                                            <div>
                                                                                {number}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                })}
                                                            </div>
                                                            {/* end list 3 tou */}
                                                        </div>}

                                                        {numberType === 2 && <div>
                                                            {/* rude nha */}
                                                            <div className={'mb-2'}>
                                                                <div className={'text-center mb-1'}>ລູດຫນ້າ</div>
                                                                <div
                                                                    className={'d-flex gap-1 mb-1 justify-content-around'}>
                                                                    {
                                                                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => {
                                                                            return <div className={'select-range w-100'}
                                                                                        onClick={() => addSwipeFront(number)}
                                                                                        key={number}>
                                                                                {pad(number, 1)}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* end rude nha */}

                                                            {/* rude lhang */}
                                                            <div className={'mb-2'}>
                                                                <div className={'text-center mb-1'}>ລູດຫລັງ</div>
                                                                <div
                                                                    className={'d-flex gap-1 mb-1 justify-content-around'}>
                                                                    {
                                                                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => {
                                                                            return <div className={'select-range w-100'}
                                                                                        onClick={() => addSwipeBack(number)}
                                                                                        key={number}>
                                                                                {pad(number, 1)}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* end rude rude lhang */}

                                                            {/* 19 pratu */}
                                                            <div className={'mb-3'}>
                                                                <div className={'text-center mb-1'}>19 ປະຕູ</div>
                                                                <div
                                                                    className={'d-flex gap-1 mb-1 justify-content-around'}>
                                                                    {
                                                                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => {
                                                                            return <div className={'select-range w-100'}
                                                                                        onClick={() => addNineTeenDoor(number)}
                                                                                        key={number}>
                                                                                {pad(number, 1)}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* end 19 pratu */}


                                                            {/* list 3 tuo */}
                                                            <div className={'grid-container hundred mb-2'}>
                                                                {numberList.map(number => {
                                                                    return <div
                                                                        className={`number-box type_2 ${isActiveNumber(number)}`}
                                                                        onClick={() => selectNumber(number)}
                                                                        key={number}>
                                                                        <div className={'number'}>
                                                                            <div>
                                                                                {number}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                })}
                                                            </div>
                                                            {/* end list 3 tou */}
                                                        </div>}
                                                        {numberType === 0 && <div>
                                                            {/* list 2 tuo */}
                                                            <div className={'grid-container ten mb-2'}>
                                                                {numberList.map(number => {
                                                                    return <div
                                                                        className={`number-box type_0 ${isActiveNumber(number)}`}
                                                                        onClick={() => selectNumber(number)}
                                                                        key={number}>
                                                                        <div className={'number'}>
                                                                            <div>
                                                                                {number}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                })}
                                                            </div>
                                                            {/* end list 2 tou */}
                                                        </div>}
                                                    </div>
                                                    }
                                                    {/* end board */}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>

                                    </div>

                                </Tab.Container>
                            </div>

                            <div className={'col-12 col-lg-4'}>

                                <div className={'card card-green'}>
                                    <div className={'card-body'}>

                                        <div className={'mb-3'}>
                                        ຈໍາ​ນວນ​ {totalCart} ລາຍການ
                                        </div>

                                        <div className={'cart'}>
                                            {selected.map(mode => {
                                                return mode.result.length > 0 &&
                                                    <div className={'mb-3'} key={`${mode.name}`}>
                                                        <div className={'mb-2'}> {mode.name}</div>
                                                        {mode.result.map(data => {
                                                            return <div
                                                                key={`${mode.name}_${data.number}`}>
                                                                <div className="input-group mb-3 cart-list">
                                                                    <div className="input-group-prepend ">
                                                                    <span
                                                                        className="input-group-text">{data.number}</span>
                                                                    </div>

                                                                    <NumericFormat
                                                                        className={`form-control bet border-0 
                                                                    ${data.amount > mode.maxBet || data.amount < mode.minBet ? 'bg-danger' : ''}`}
                                                                        placeholder={'จำนวนเงิน'}
                                                                        inputMode={'numeric'}
                                                                        thousandSeparator=","
                                                                        allowLeadingZeros
                                                                        allowNegative={false}
                                                                        value={data.amount}
                                                                        onChange={(e) => {
                                                                            setAmount({
                                                                                id: mode.id,
                                                                                number: data.number,
                                                                                amount: e.target.value.replace(/\,/g, '')
                                                                            })
                                                                        }}
                                                                    />

                                                                    <div className="input-group-prepend ">
                                                                    <span
                                                                        className="input-group-text reward h-100">
                                                                        <span className={'me-2'}>  ຊະນະ</span>
                                                                         <span
                                                                             className={'me-2'}>  {numberWithCommas(data.amount * mode.rate)} </span>
                                                                        <span
                                                                            className={'badge bg-primary'}>  {mode.rate} ฿</span></span>
                                                                    </div>
                                                                    <div className="input-group-prepend">
                                                                        <button
                                                                            className="btn btn-danger h-100 btn-delete"
                                                                            onClick={() => deleteNumber(data)}
                                                                            type="button"><i
                                                                            className="far fa-trash-alt"/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                            })}
                                        </div>


                                        <div className={'mb-3'}>
                                        ເອົາຈໍານວນດຽວກັນທັງຫມົດ
                                        </div>

                                        <div className={'mb-3'}>
                                            <NumericFormat
                                                className={'form-control form-control-lg input-custom custom__input'}
                                                placeholder={'ຈໍາ​ນວນ​'}
                                                inputMode={'numeric'}
                                                thousandSeparator=","
                                                allowLeadingZeros
                                                allowNegative={false}
                                                onChange={(e) => {
                                                    setAllAmount(e.target.value.replace(/\,/g, ''))
                                                }}
                                            />
                                        </div>

                                        <div className={'d-flex justify-content-around gap-2 mb-3'}>
                                            {[1000, 2000, 5000, 10000].map(number => {
                                                return <div className={'btn-money flex-fill'} key={number}
                                                            onClick={() => setAllAmount(number)}>
                                                    {numberWithCommas(number)}
                                                </div>
                                            })}
                                        </div>

                                        <div className={'mb-3'}>
                                        ຈໍາ​ນວນ​ທັງ​ຫມົດ : {numberWithCommas(total)} ກີບ
                                        </div>

                                        <div className={'mb-3 row'}>
                                            <div className={'col-6'}>
                                                <div className={'btn btn-danger w-100'}
                                                     onClick={() => clearCart()}> ລ້າງຂໍ້ມູນ
                                                </div>
                                            </div>
                                            <div className={'col-6'}>
                                                <div className={'btn btn-primary w-100'} onClick={()=> saveHuay()}> ສົ່ງໂພຍ</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} msg={`ຂັ້ນຕ່ຳ 1,000 ກີບ`} onHide={()=>setShow(false)}  />
        </div>
    );
}

export default StockSelect;
