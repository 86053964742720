import Logo from "../components/Logo";
import {useNavigate} from "react-router-dom";
import {login} from "../store/user";
import {useDispatch} from "react-redux";
import {useState} from "react";

function PaymentInfo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [bank] = useState([
        {id: 1, name: "ທະນາຄານການຄ້າຕ່າງປະເທດລາວ (BCEL)"},
        {id: 2, name: "ທະນາຄານພັດທະນາລາວ (LDB)"},
        {id: 3, name: "ທະນາຄານສົ່ງເສີມກະສິກຳ (APB)"},
        {id: 4, name: "ທະນາຄານລາວ-ຫວຽດ (LVB)"}
    ])

    const register = async () => {
        await dispatch(login());
        return navigate("/");
    }

    return (
        <div className={'container'}>
            <div className={'row justify-content-center align-items-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} data-aos="fade-up">

                    <div className={'text-center'}>
                        <div className={'mb-3'}>
                            <Logo style={{maxHeight: '100px'}}/>
                        </div>
                    </div>

                    <div className={'card card-green mt-4 py-3'}>
                        <div className={'card-body'}>
                            <h3 className={'text-center'}>
                                ໃສ່ຂໍ້ມູນທະນາຄານ
                            </h3>

                            <div className={'text-center mb-4 text-secondary'}>
                                ลงทะเบียนสมาชิกใหม่หรือเข้าสู่ระบบง่ายๆ <br/>เพียงระบุเบอร์โทรศัพท์ของคุณเท่านั้น
                            </div>


                            <div className={'d-flex gap-2 mb-3'}>
                                <select className={'form-control form-control-lg custom__input'}>
                                    <option className={'text-black'}>
                                        ເລືອກທະນາຄານ
                                    </option>

                                    {bank.map(data => {
                                        return <option className={'text-black'}>
                                            {data.name}
                                        </option>
                                    })}
                                </select>
                            </div>

                            <div className={'d-flex gap-2 mb-3'}>
                                <input className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ໃສ່ຊື່ບັນຊີ'}/>
                            </div>

                            <div className={'d-flex gap-2 mb-3'}>
                                <input className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ໃສ່ເລກບັນຊີ (ບໍ່ໃສ່ -)'}/>
                            </div>

                            <div className={'d-flex gap-2 mb-4'}>
                                <input className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ຢືນຢັນເລກບັນຊີ'}/>
                            </div>

                            <div className={'text-center mb-3'}>
                                <div className={'w-100 btn btn-primary btn-lg'} onClick={register}>ຢືນຢັນສະຫມັກສະມາຊິກ
                                </div>
                            </div>

                            <div className="separator my-4"/>

                            <div className={'text-center mb-3'}>
                                <div className={'w-100 btn btn-danger btn-lg'}>
                                    ຕິດຕໍ່ - ສອບຖາມ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentInfo;
