import { WEBNAME } from "../store/config";
/*
SBD
  <img className={'img-fluid'} style={{maxHeight:95}} src={`${process.env.PUBLIC_URL}/images/${WEBNAME}/logo2.png`} alt={'logo'}/>
*/
function Logo(props) {
    return (
        <img className={'img-fluid'} style={{maxWidth:130}} src={`${process.env.PUBLIC_URL}/images/${WEBNAME}/logo.png`} alt={'logo'}/>
    );
}

export default Logo;
