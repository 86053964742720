import {useEffect, useMemo} from "react";

import PageHeader from "../../components/PageHeader";
import { useParams, useNavigate } from "react-router-dom";

import { huayrate } from "../../store/user";
import { useDispatch, useSelector } from "react-redux";


function StockSelect() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { rateitems, rateitem } = useSelector(state=>state.user)
    const { huaysubId } = useParams()
   
    useEffect(() => {
        // init
        dispatch(huayrate({huaysubId}))
        console.log('init')
    }, []);

    useMemo(() => {
        if(rateitems.length > 0) {
           const newitems = JSON.stringify(rateitems)
           const newitem = JSON.stringify(rateitem)
           localStorage.setItem('rateitems', newitems)
           localStorage.setItem('rateitem', newitem)
           navigate(`/lotto/stocklink/${huaysubId}`)
        }
    } ,[rateitems])
   

    return (
        <div className={'container hoon'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12'}>

                    {/* page title */}
                    <PageHeader logo backPath={'/lotto/stock'}/>
                    {/* end page title */}

                    <div>
        <div style={{height:80}}></div>
        <div className="ring">LOADING
    <span className='span1'></span>
  </div>
  <div style={{height:180}}></div>
 
  </div>


                </div>
            </div>
        </div>
    );
}

export default StockSelect;
