import React, { useState, useEffect } from "react";
import { getRemainingTimeUntilMstimestamp } from "./ConutdownTimerUntils";
//import { } 're'
//import { } from ''
const defaultRemainTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
  end: 1,
};
const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainTime);
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainTime(countdownTimestampMs);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, []);
  function updateRemainTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMstimestamp(countdown));
  }
 return (
      <div className="appcountdown">
        <span>
          <img src={`/images/icon/time.png`} style={{maxWidth:28, paddingRight:5}} />
        </span>
        {remainingTime.days != "00" && <span>{remainingTime.days}</span>}
        {remainingTime.days != "00" && <span>ມື້</span>}
        {remainingTime.days == "00" &&
          remainingTime.hours == "00" &&
          remainingTime.minutes == "00" &&
          remainingTime.seconds == "00" &&
          remainingTime.end == 2 && <span>ຍັງບໍ່ເປີດຮັບແທງ</span>}
  
        {remainingTime.end != 2 && (
          <span>
            {remainingTime.hours}:{remainingTime.minutes}:{remainingTime.seconds}
          </span>
        )}
      </div>
    );
  
};

export default CountdownTimer;
