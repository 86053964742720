
import io from "socket.io-client";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
//import { useNavigate } from "react-router-dom";
import { ENDPOINT } from '../store/config'
import { getbalance,setStep } from '../store/user'
function LoginModal() {
    const dispatch = useDispatch();
    //const navigate = useNavigate()
    useEffect(() => {
        //startsocket();
        const nio = io(ENDPOINT);
    
        nio.on("sentsms", (messageNew) => {
        
        const checkdata = messageNew?.notifyitems?.find(x=>`${x.username}` === `${localStorage.getItem('username')}`)
        //if(`${localStorage.getItem('username')}` === `${messageNew?.username}`) {
        console.log('username', localStorage.getItem('username'))
        console.log('messageNew', messageNew)
        console.log('checkdata', checkdata)
        if(checkdata) {
           
            const {status, idbill, qrcode, nameth, table, deposittype, type } = checkdata
            if(status === 3 && table === 'history' && type !== 2) {
                // ยืนยันฝากแล้ว
                dispatch(getbalance({}))
                dispatch(setStep({accountdeposit:messageNew}))
                if(deposittype === 'credit') {
                    window.location.href = `/`
                }else{
                    if(nameth === `รัฐบาลไทย`) {
                        window.location.href = `/bill/huay-thai/${idbill}`
                    }else if(idbill){
                        window.location.href = `/bill/huay-hoon/${idbill}`
                    } else {
                        window.location.href = `/`
                    }
                }
                //navigate(`/bill/huay-thai/${messageNew?.huayitem?.result?.id}`)
            } else  if(status === 4) {
                window.location.href = `/`
                // ยกเลิกฝาก
                if(messageNew?.deposittype === 'credit') {
                    window.location.href = `/`
                }else{
                    dispatch(setStep({accountdeposit:messageNew}))
                }
               
            } else  if(status === 3 && type ===2) {
                // ยืนยันถอน
                dispatch(setStep({accountwithdraw:checkdata}))
            } else  if(status === 4 && type ===2) {
                // ยกเลิกถอน
                dispatch(setStep({accountwithdraw:checkdata}))
            } else if(parseInt(status) === 1 && qrcode && table === 'run'){
                // แอดมินแนบสลิปมาแล้ว
                window.location.reload()
                dispatch(setStep({step:3, accountdeposit:checkdata}))
            /*
            } else if(parseInt(messageNew?.checkqrcode) === 1){
                // แอดมินแนบสลิปมาแล้ว
                dispatch(setStep({step:3, accountdeposit:messageNew}))
            */
            } else {
                // ส่ง Qrcode แล้ว
                //dispatch(setStep(4))
                //dispatch(setQrcode(messageNew?.qrcode))
                //dispatch(setSid(messageNew?.sid))
                //dispatch(setMtimestamp(messageNew?.mtimestamp))
            }
        }
          // ส่งไปอับเดทให้ ผู้ใช้งคนอื่นๆรับรู้
        });
        }, [])
    return (
       <span></span>
    );
}

export default LoginModal;
