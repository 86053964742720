import {Modal} from "react-bootstrap";
import Banner from './SwiperPupop'
function LoginModal(props) {
   
    return (
        <Modal {...props} centered className={'popup-modal'}>
                <div className={'closeloginbtn'} onClick={props.onHide}>X</div>

            <Modal.Body className={'contentmodal py-4'}>
               <Banner />
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
