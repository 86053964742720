import {Modal} from "react-bootstrap";

function LoginModal(props) {
   
    return (
        <Modal {...props} centered className={'login-modal'}>
                <div className={'closeloginbtn'} onClick={props.onHide}>X</div>

            <Modal.Body className={'contentmodal py-4'}>
                <div className="text-center">
                <img src='/images/approve.png' style={{maxWidth:80}} /> 
                <div className="text-info">เครดิตเติมเข้ากระเป๋าลูกค้าแล้วนะคะ</div>
                </div>
                
             
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
