import PageHeader from "../components/PageHeader";
import {numberWithCommas} from "../utils/math";
import {NumericFormat} from "react-number-format";
import {useSelector, useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom'
import {useState, useEffect, useMemo} from "react";
import { createqrcodedeposit, checkqrcode } from '../store/user'
import { Spinner } from "react-bootstrap";
import Popup from '../components/PupupDeposit'
function Deposit() {
    const [amount, setAmount] = useState("");
    const [bank, setBank] = useState("bcel");
    const { isLoadingLogin, accountdeposit } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [payments] = useState([
        {id: 'bcel', image: "/images/icon/one.png", name: "BCEL One"},
       // {id: 'umoney', image: "/images/icon/umoney.png", name: "U-money"}
    ])

    const [showpop, setshowpop] = useState(true)

    const deposit = () => {
        if(isLoadingLogin === false) {
            dispatch(createqrcodedeposit({amount:amount, deposittype:'credit', bankdata:bank, navigate}))
        }
        
    }
    useEffect(()=>{
        dispatch(checkqrcode({}))
    },[])

    useMemo(()=> {
        if(accountdeposit?.qrcode){
            navigate('/lotto/tunsamai/depositconfirm')
        }
    },[accountdeposit?.qrcode])

    return (
        <div className={'container deposit'}>
            <div className={'row justify-content-center min-vh-100 py-5'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo logoSize={'80'} backPath={'/'}/>
                    {/* end page title */}


                    {/* announcement */}
                    {
                        accountdeposit?.close === 1 && <div className={'mt-5 mb-3 card card-green profile'} data-aos="fade-up">
                        <div className={'card-body'}>
                            <img alt={'announcement'}
                                height={'20px'}
                                width={'20px'}
                                className={'me-2'}
                                src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/> ຝາກເງິນໃນເວລາໃຫ້ບໍລິການ (08.30 - 01.00 ໂມງ )
                        </div>
                    </div>
                    }
                    
                    {/* end announcement */}
{
    accountdeposit?.close === 0 && <div className={'mt-5 mb-3 card card-green profile'} >
        <div className={'card-body'}>
            <div className="text-center pb-3">
            <img alt={'announcement'}
                                height={'20px'}
                                width={'20px'}
                                className={'me-2'}
                                src={`${process.env.PUBLIC_URL}/images/icon/warning.png`}/>
            </div>
        ລະບົບ ຝາກ-ຖອນ ປິດການແລ້ວຕັ້ງແຕ່ເວລາ 02:00ນ
ເປີດໃຫ້ບໍລິການອີກໃນເວລາ 08:30ນ ຈ້າວ
        </div>
    </div>
}

  {/* payments  */}
  {
    accountdeposit?.close === 1 && <div className={'mb-5'}>
    <div className={'text-primary h5'}>
        ເລືອກທະນາຄານ
    </div>

    {payments.map(data => {
        return <div className={'card card-green mb-1'} key={`${data.id}`}>
            <div className={'py-2 px-3'}>
                <div className={'d-flex gap-3 justify-content-between align-items-center'}>
                    <div className={'d-flex gap-3 align-items-center'}>
                        <img className="img-fluid img-payment"
                             src={`${process.env.PUBLIC_URL}${data.image}`}
                             alt={data.name}/>
                        <div>
                            <div className={'text-secondary'}>
                                
                            </div>
                            <div className={'text-primary'}>
                                {data.id !== 1 && data.name}
                               
                            </div>
                        </div>
                    </div>
                    <div className={'text-end'}>
                        {
                            bank === data.id ? <button className={'btn btn-primary text-decoration-none'} >ເລືອກ</button> : <button className={'btn btn-primary-no text-decoration-none'} onClick={()=>setBank(data.id)} >ເລືອກ</button>
                        }
                    
                    
                    </div>
                </div>
            </div>

        </div>
    })}
</div>
  }
  
                    {/* end payments  */}

                    {/* deposit card */}
                    {
                        accountdeposit?.close === 1 && <div className={'mb-5'}>
                        <div className={'card card-green mb-5 profile'}>
                            <div className={'card-body my-3'}>

                                <div className={'mb-2'}>
                                    <NumericFormat
                                        className={'form-control form-control-lg input-custom custom__input'}
                                        placeholder={'ຈຳນວນເງິນຝາກຂັ້ນຕ່ຳຄື 1,000 ກີບ'}
                                        inputMode={'numeric'}
                                        thousandSeparator=","
                                        allowLeadingZeros
                                        disabled
                                        allowNegative={false}
                                        onChange={e => setAmount(e.target.value.replace(/\,/g, ''))}
                                        value={amount}/>
                                </div>


                                <div className={'mb-4'}>
                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[1000, 2000, 3000].map(number => {
                                            return <div className={'btn-money flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[5000, 7000, 8000].map(number => {
                                            return <div className={'btn-money flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[10000, 20000, 30000].map(number => {
                                            return <div className={'btn-money2 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>
                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[50000, 70000, 80000].map(number => {
                                            return <div className={'btn-money2 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[100000, 200000, 300000].map(number => {
                                            return <div className={'btn-money3 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>
                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[500000, 700000, 800000].map(number => {
                                            return <div className={'btn-money3 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                    <div className={'d-flex justify-content-around gap-2 mb-2'}>
                                        {[1000000, 2000000, 3000000].map(number => {
                                            return <div className={'btn-money4 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>
                                    <div className={'d-flex justify-content-around gap-2'}>
                                        {[5000000, 10000000, 20000000].map(number => {
                                            return <div className={'btn-money4 flex-fill'} key={number}
                                                        onClick={() => setAmount(number)}>
                                                {numberWithCommas(number)}
                                            </div>
                                        })}
                                    </div>

                                </div>

                                <div className={'gap-3 justify-content-center align-items-end'}>

                                    <div className={'btn btn-primary btn-lg w-100'}
                                         onClick={() => deposit()}>
                                        ຢືນຢັນ { isLoadingLogin === true && <Spinner size="sm" />}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    }
                    
                    {/* end deposit card */}

                </div>
            </div>
            <Popup  show={showpop} onHide={()=>setshowpop(false)} />
        </div>
    );
}

export default Deposit;
