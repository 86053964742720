import {configureStore} from '@reduxjs/toolkit'
import userReducer from './user'
import tunsamaiReducer from "./tunsamai";
import namsudReducer from "./namsud";

export const store = configureStore({
    reducer: {
        user: userReducer,
        tunsamai: tunsamaiReducer,
        namsud: namsudReducer
    },
})
