import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import { useSelector, useDispatch } from 'react-redux'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";


export default function SwiperBanner() {
    const [banners] = useState([
        {name: 'banner 1', image: '/images/banner/01.png'},
        {name: 'banner 2', image: '/images/banner/01.png'},
        {name: 'banner 3', image: '/images/banner/01.png'},
        {name: 'banner 4', image: '/images/banner/01.png'},
        {name: 'banner 5', image: '/images/banner/01.png'},
        {name: 'banner 6', image: '/images/banner/01.png'},
    ]) || [];
   
    const {banneritems} = useSelector(state => state.user);

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                slidesPerGroup={1}
                loop={true}
                navigation={false}
                pagination={{
                    dynamicBullets: true
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Mousewheel, Pagination, Autoplay, Navigation]}
                style={{
                    "--swiper-navigation-color": "white",
                    "--swiper-navigation-size": "25px",
                    "--swiper-pagination-color": "#E8C973",
                    "--swiper-pagination-bullet-inactive-color": "white",
                    "--swiper-pagination-bullet-inactive-opacity": 0.2,
                    "--swiper-pagination-bullet-opacity": 1,
                }}
            >

                {banneritems && banneritems.filter(x=>parseInt(x.type)===1).map(banner => {
                    return <SwiperSlide key={banner.name}>
                        <img className="w-100 swiper-image"
                             src={`https://100lankip2.com/images/banner/${banner.photo}`}
                        alt={banner.name}/>
                    </SwiperSlide>
                })}
            </Swiper>
        </>
    );
}
