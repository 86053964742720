import {Outlet} from "react-router-dom"
import MainMenuBottom from "../components/MainMenuBottom";
import ScrollToTop from "../components/ScrollToTop";

function MainLayout() {
   
    return (
        <>
            <ScrollToTop/>
            <main>
                <div className={'mb-5'}>
                    <Outlet/>
                </div>

                <MainMenuBottom/>
            </main>
        </>
    );
}

export default MainLayout;
