import {Outlet} from "react-router-dom"
import ScrollToTop from "../components/ScrollToTop";

function DefaultLayout() {
    return (
        <>
            <ScrollToTop/>
            <main>
                <Outlet/>
            </main>
        </>
    );
}

export default DefaultLayout;
