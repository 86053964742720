import {NavLink} from "react-router-dom";

function BillMenu({activePath}) {
    return (
        <div className={'card card-green mb-3'} data-aos="fade-up">
            <div className={'card-body text-decoration-none'}>
                <div className="row bill-menu">
                    <div className="col">
                        <NavLink className={`d-block ${activePath === 'bill' ? 'active' : ''}`} to={'/bill/huay-thai'}>
                        ບິນຫວຍ
                        </NavLink>
                    </div>
                    <div className="col">
                        <NavLink className={`d-block ${activePath === 'result' ? 'active' : ''} `} to={'/result'}>
                        ຜົນຫວຍ
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>);
}

export default BillMenu;
