import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

const PrivateRoute = ({children}) => {
    const user = useSelector(state => state.user);
    let location = useLocation();

    if (!localStorage.getItem('access_token')) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
}

export default PrivateRoute;
