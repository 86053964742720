import Logo from "../../components/Logo";
import {Nav} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useState, useMemo } from 'react';
import {Link} from "react-router-dom";
import TunsamaiMenu from "../../components/TunsamaiMenu";
import {addToCart, clearCart, removeToCart} from "../../store/tunsamai";
import { gamelist, getlimithuay, checkqrcode } from '../../store/user'
import {numberWithCommas} from "../../utils/math";
import PageHeader from "../../components/PageHeader";
import CountdownTimer from "./CountdownTimer";
import * as _ from "underscore";
import moment from "moment";
import { VDO } from "../../store/config";
import VedioMoal from './ModalVDO'

function Tunsamai() {
    const {cart} = useSelector(state => state.tunsamai);
    const {gameitem, isLoading, result_items, accountdeposit} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
   const showVDO = () => {
    setShow(true)
   }

   useEffect(()=>{
    dispatch(checkqrcode({}))
},[])

useMemo(()=> {
    if(accountdeposit.depositstep === 3 || accountdeposit.depositstep === 4) {
        window.location = '/lotto/tunsamai/depositconfirm'
    }
},[accountdeposit])

    const checkimg = (q, type) => {
        const bill = [
            {id: 1, image: "01", name: "ປານ້ອຍ", numbers: ['01', '41', '81']},
            {id: 2, image: "02", name: "ຫອຍ", numbers: ['02', '42', '82']},
            {id: 3, image: "03", name: "ຫ່ານ", numbers: ['03', '43', '83']},
            {id: 4, image: "04", name: "ນົກຍູງ", numbers: ['04', '44', '84']},
            {id: 5, image: "05", name: "ສິງ", numbers: ['05', '45', '85']},
            {id: 6, image: "06", name: "ເສືອ", numbers: ['06', '46', '86']},
            {id: 7, image: "07", name: "ຫມູ", numbers: ['07', '47', '87']},
            {id: 8, image: "08", name: "ກະຕ່າຍ", numbers: ['08', '48', '88']},
            {id: 9, image: "09", name: "ຄວາຍ", numbers: ['09', '49', '89']},
            {id: 10, image: "10", name: "ນາກນ້ຳ", numbers: ['10', '50', '90']},
            {id: 11, image: "11", name: "ໝາ", numbers: ['11', '51', '91']},
            {id: 12, image: "12", name: "ມ້າ", numbers: ['12', '52', '92']},
            {id: 13, image: "13", name: "ຊ້າງ", numbers: ['13', '53', '93']},
            {id: 14, image: "14", name: "ແມວບ້ານ", numbers: ['14', '54', '94']},
            {id: 15, image: "15", name: "ຫນູ", numbers: ['15', '55', '95']},
            {id: 16, image: "16", name: "ເຜິ້ງ", numbers: ['16', '56', '96']},
            {id: 17, image: "17", name: "ນົກຍາງ", numbers: ['17', '57', '97']},
            {id: 18, image: "18", name: "ແມວປ່າ", numbers: ['18', '58', '98']},
            {id: 19, image: "19", name: "ກະເບື້ອ", numbers: ['19', '59', '99']},
            {id: 20, image: "20", name: "ຂີ້ເຂັບ", numbers: ['20', '60', '00']},
            {id: 21, image: "21", name: "ນົກແອ່ນ", numbers: ['21', '61']},
            {id: 22, image: "22", name: "ນົກກາງແກ", numbers: ['22', '62']},
            {id: 23, image: "23", name: "ລິງ", numbers: ['23', '63']},
            {id: 24, image: "24", name: "ກົບ", numbers: ['24', '64']},
            {id: 25, image: "25", name: "ແຫຼວ", numbers: ['25', '65']},
            {id: 26, image: "26", name: "ນາກບິນ", numbers: ['26', '66']},
            {id: 27, image: "27", name: "ເຕົ່າ", numbers: ['27', '67']},
            {id: 28, image: "28", name: "ໄກ່", numbers: ['28', '68']},
            {id: 29, image: "29", name: "ອ່ຽນ", numbers: ['29', '69']},
            {id: 30, image: "30", name: "ປາໃຫຍ່", numbers: ['30', '70']},
            {id: 31, image: "31", name: "ກຸ້ງ", numbers: ['31', '71']},
            {id: 32, image: "32", name: "ງູ", numbers: ['32', '72']},
            {id: 33, image: "33", name: "ແມງມຸມ", numbers: ['33', '73']},
            {id: 34, image: "34", name: "ກວາງ", numbers: ['34', '74']},
            {id: 35, image: "35", name: "ແບ້", numbers: ['35', '75']},
            {id: 36, image: "36", name: "ເຫງັນ", numbers: ['36', '76']},
            {id: 37, image: "37", name: "ຫຼິ່ນ", numbers: ['37', '77']},
            {id: 38, image: "38", name: "ເໝັ້ນ", numbers: ['38', '78']},
            {id: 39, image: "39", name: "ກະປູ", numbers: ['39', '79']},
            {id: 40, image: "40", name: "ນົກອິນຊີ", numbers: ['40', '80']},
        ]

        const newnumber = `${q}`
        let nnumber = `${q}`
        if(newnumber.length === 1) {
            nnumber = `0${q}`
        }else if(newnumber.length > 2) {
            nnumber = newnumber.slice(-2)
        }else {
            nnumber = `${q}`
        }

        const data  = _.find(bill, function (data) {
            return data.numbers.includes(nnumber);
        });

        return data?.[type]
    }

    useEffect(()=> {
        dispatch(gamelist({}))
        dispatch(getlimithuay({}))
    } ,[])
    const add = (data) => {
        if (!data.list) return;
        dispatch(addToCart(data));
    }

    const clear = () => {
        dispatch(clearCart());
    }

    const removeId = (id) => {
        dispatch(removeToCart({id}))
    }


    return (<div className={'container lotto-container'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} 
                //data-aos="fade-up"
                >

                    {/* page title */}
                    <PageHeader backPath={'/lotto'} goPath={'/bill/huay-thai'}/>
                    {/* end page title */}

                    <div className={'text-center mb-2'}>
                        <div className={'mb-1'}>
                            <Logo style={{maxHeight: '73px'}}/>
                        </div>
                        {
                            VDO.open === 1 &&  <a href={`${VDO.urlall}`} target="_blank"><div className={'btn btn-danger'} >
                            VDO ຂັ້ນ​ຕອນ​ຊື້ເລກ
                            </div></a>
                        }
                       <div className={'btn btn-danger'} style={{paddingLeft:5, marginLeft:10}} onClick={()=>showVDO()} >
                       ອັດຕາຈ່າຍ
                            </div>
                        <div className={'text-primary'}>
                        ຕົວແທນຈຳນ່າຍຫວຍອອນລາຍໃນລາວ
                        </div>
                    </div>

                    {/* tab menu */}
                    <div className={'lotto-tab'}>
                        <Nav variant="tabs" defaultActiveKey="tunsamai"
                             justify>
                            <Nav.Item>
                                <Nav.Link eventKey="tunsamai" as={Link} to={`/lotto/tunsamai`}>
                                    <img className="icon" alt={'menu'}
                                         src={`${process.env.PUBLIC_URL}/images/icon/tunsamai_active.png`}/>
                                    {gameitem?.mastername}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* end  tab menu */}

                    <div className={'card card-green bg-changkum'}>
                        <div className={'card-body my-4'}>
                       
                            {/* countdown */}
                            {cart.length === 0 && <div>
                                <div className={'text-center mb-3'}>
                                    <div className={'text-secondary'}>
                                    ງວດວັນທີ
                                    </div>
                                    <h3 className={'mb-3 text-primary'}>
                                        {gameitem?.startdateth}
                                    </h3>

                                    <div>
                                    ປິດຮັບອີກພາຍໃນເວລາ
                                    </div>
                                </div>
                                {
                                    isLoading === true ? '' :  <CountdownTimer countdownTimestampMs={gameitem?.enddatetime} />
                                 }
                               
                                <hr className={'my-4 hr-secondary'}/>
                              
                                {result_items && result_items.map(data => {
                                return <div className={'card card-dark mb-3'} data-aos="fade-up" key={data.id}>
                                    <div className={'card-body'}>
                                        <div className={'row align-items-start'}>

                                            <div className={'col-12 pe-1'}>
                                                <div className={'d-flex justify-content-between mb-1'}>
                                                    <div className={'text-secondary'}>
                                                    ງວດທີ : {data.no}
                                                    </div>
                                                    <div className={'text-primary mb-0'}>
                                                        {moment(data.startdate).format("DD/MM/YYYY")}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className={'col-auto text-center'}>
                                                <div>
                                                    <img className="img-fluid" alt={'animal'}
                                                         src={`${process.env.PUBLIC_URL}/images/animal/${checkimg(data?.bet6result,'image')}.png`}
                                                         style={{width: "30px"}}/>
                                                </div>
                                                <div className={'lh-1'}>
                                                   
                                                    {checkimg(data?.bet6result,'name')}
                                                </div>
                                            </div>

                                            <div className={'col'}>
                                                <div className={'row text-center justify-content-center'}>
                                                    {[data?.bet6result[0],data?.bet6result[1],data?.bet6result[2],data?.bet6result[3],data?.bet6result[4],data?.bet6result[5]].map((number, index) => {
                                                        return <div className={'col px-1'}
                                                                    key={`${data.round}_${index}`}>
                                                            <h5 className={'result mb-0'}>
                                                                {number}
                                                            </h5>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'col-12 pe-3'}>
                                                <div className={'d-flex justify-content-between mb-3'}>
                                                    <div className={'text-secondary'}>
                                                   
                                                    </div>
                                                    <div className={'row text-right justify-content-center'}>2ໂຕລ່າງ
                                                    {[data?.bet2lower[0],data?.bet2lower[1]].map((number, index) => {
                                                        return <div className={'col px-1'}
                                                                    key={`${data.round}_${index}`}>
                                                            <h5 className={'result mb-0'}>
                                                                {number}
                                                            </h5>
                                                        </div>
                                                    })}
                                                    </div>
                                                </div>
                                            </div>

                                </div>
                            })}
                            </div>}
                            {/* end countdown */}

                            {/* cart */}
                            {cart.length > 0 && <div className={'cart'}>
                                <div className={'d-flex justify-content-between text-secondary mb-1'}
                                     onClick={clear}>
                                    <div>ເລກທີ່ເລືອກ</div>
                                    <div>ຈໍານວນເງິນ <img className="ms-1 icon-cancel" alt={'cancel'}
                                                        src={`${process.env.PUBLIC_URL}/images/icon/cancel_order.png`}/>
                                    </div>
                                </div>

                                {cart.map((data, index) => {
                                    return <div className={'list'} key={`${data.name}_${index}`}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col-auto'}>
                                                <img className="icon-animal" alt={'animal'}
                                                     src={`${process.env.PUBLIC_URL}/images/animal/${data.image}.png`}/>
                                            </div>

                                            {
                                                /*
 <div className={'col'}>
                                                <div className={'d-flex gap-3 number'}>
                                                    {data.result.map((number, index) => {
                                                        return <div key={`${data.name}_${index}`}
                                                        >{number}</div>
                                                    })}
                                                </div>
                                            </div>
                                                */
                                            }
                                    
                                            <div className={'col'}>
                                                <div className={'d-flex gap-3 number'}>
                                                <div >
                                                        {data.number}{data.type === '2lower' && <span className="lt2">ລ່າງ</span>}</div>

                                                </div>
                                            </div>

                                            <div className={'col-auto d-flex align-items-center text-end'}>
                                                <span
                                                    className={'amount me-1'}>  {numberWithCommas(data.amount)} </span>
                                                <img
                                                    onClick={()=> removeId(data.id)}
                                                    alt={'cancel'}
                                                    className="icon-cancel"
                                                    src={`${process.env.PUBLIC_URL}/images/icon/cancel_order.png`}/>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>}
                            {/* end cart */}

                        </div>
                    </div>
                </div>
            </div>
            <VedioMoal filename='rate2.png' show={show} onHide={()=>setShow(false)}/>
            <TunsamaiMenu addToCart={add} showSearch={true}/>
        </div>
    );
}

export default Tunsamai;
