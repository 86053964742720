import {Modal} from "react-bootstrap";

function LoginModal(props) {
   
    return (
        <Modal {...props} centered className={'login-modal'}>
            {
                /*
<div className={'closeloginbtn'} onClick={props.onHide}>X</div>
                */
            }
                

            <Modal.Body className={'contentmodal py-4'}>
                <div className="text-center mb-1 boxmodal mt-3">
                <img src={props.img ? props.img:`/images/reject.png`} style={{maxWidth:50, paddingTop:20, paddingBottom:20}} /> 
                <div className="text-info3">{props.msg}</div>
                </div>
                
              
                <div className={'btn btn-primary btn-lg w-100'}
                                    onClick={props.onHide}    >
                                        ປິດ
                                    </div>
                
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
