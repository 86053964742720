import {useState, useEffect, useMemo, useRef} from "react";

import {useSelector, useDispatch} from "react-redux";
import { useNavigate} from 'react-router-dom'

import PageHeader from "../components/PageHeader";

import { getbalance, memberchangpass } from "../store/user";

import { Spinner } from "react-bootstrap";
import { WEBTOKEN } from "../store/config";
function Affiliate() {
   
    const { isLoadingBtn:isLoading, item, showPass, reloadPass } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getbalance({}))
    },[])
    const navigate = useNavigate()

    const txtpassword1 = useRef(null)
    const txtpassword2 = useRef(null)
    const txtpassword = useRef(null)

    const signup = () => {
        const password1 = txtpassword1.current.value;
        const password2 = txtpassword2.current.value;
        const password = txtpassword.current.value;
      
       
        if(!password){
            txtpassword.current.focus()
        } else if(!password1 && password2){
            txtpassword2.current.focus()

        }else if(!password1){
            txtpassword1.current.focus()
        }else if(!password2){
            txtpassword2.current.focus()
        } else {
            const data = {
                password,
                newpassword:password1,
                webtoken:WEBTOKEN,
            }
            dispatch(memberchangpass(data));
        }
        
    }

    useMemo(()=> {
        if(reloadPass === 1){
            const timer = setTimeout(() => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('username')
                window.location = '/'
              }, 3000);
              return () => clearTimeout(timer);
            
        }
    } , [reloadPass])

    return (<div className={'container affiliate'}>
        <div className={'row justify-content-center min-vh-100 py-4'}>
            <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                {/* page title */}
                <PageHeader logo/>
                {/* end page title */}

                {/* affiliate id */}
                <div className={'card card-green mb-5'} data-aos="fade-up">

              

                    <div className={'card-body'}>
                        <div className={'text-center'}>
                        ຂໍ້ມູນສ່ວນຕົວ
                        </div>
                        
                        <hr/>
                    
                            <div className="text-center"> <img src={`${item?.bankphoto}`} style={{maxWidth:60}} /></div>
                           <div className="pt-2"></div>
                        <ul>
                        <li>
                        ທະນາຄານ : <span className={'text-primary'}> {item?.bankname}</span>
                            </li>
                            <li>
                            ຊື່ບັນຊີ : <span className={'text-primary'}> {item?.accname}</span>
                            </li>
                            <li>
                            ເລກບັນຊີ : <span className={'text-primary'}> {item?.accno}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* end affiliate id */}

              
                <div className={'card card-green mb-5 overview'} >
                    <div className={'card-body'}>
                    <div className={'row mb-3 affiliate-menu justify-content-center'}>
                    ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ
                    </div>
                    <div className={'d-flex gap-2 mb-2'}>
                                <input ref={txtpassword} name="password" type="password" className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ໃສ່ລະຫັດຜ່ານເກົ່າ'}/>
                            </div>
                            {
                                showPass === true && <div className={'d-flex gap-2 mb-2 text-warning'}>
                                ໃສ່ລະຫັດຜ່ານເກົ່າ ບໍ່ຖືກຕ້ອງ
                            </div>
                            }

                            <div className={'d-flex gap-2 mb-2'}>
                                <input ref={txtpassword1}  name="password1" type="password" className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ໃສ່ລະຫັດຜ່ານໃຫມ່'}/>
                            </div>
                            <div className={'d-flex gap-2 mb-2'}>
                                <input ref={txtpassword2}  name="password2" type="password" className={'form-control form-control-lg input-custom custom__input'}
                                       placeholder={'ຢືນຢັນລະຫັດຜ່ານໃຫມ່'}/>
                            </div>
                            {
                                reloadPass === 1 &&  <div className="d-flex justify-content-center">
                                <div><img src={`/images/approve.png`} style={{maxHeight:45}} /> 
                                </div>
                                
                            </div>
                            }
                           {
                            reloadPass === 1 &&  <div className="d-flex justify-content-center">
                                
                            <div>ປ່ຽນ​ລະ​ຫັດ​ຜ່ານສໍາເລັດ ຈະອອກຈາກລະບົບ ພາຍໃນ 3 ວິນາທີ</div> 
                        </div>
                           }
                           
                            <div onClick={()=>signup()} className={'border-primary btn btn-primary w-100 border  px-3 py-2 rounded'}>
                            ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ {isLoading === true && <Spinner size="sm" /> }
                            </div>
                    </div>
                </div>
               


               
            </div>
        </div>
        <div className="linefixed" onClick={()=>navigate('/contacts')}><img width="150" height="231" src="/images/thaiman.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/thaiman.png 150w, /images/thaiman.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
    </div>);
}

export default Affiliate;
