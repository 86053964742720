import * as _ from "underscore";

export const tod3 = [
    {id: 1, number: ["102", "120", "021", "012", "210", "201"]},
    {id: 2, number: ["103", "130", "031", "013", "310", "301"]},
    {id: 3, number: ["095", "059", "950", "905", "509", "590"]},
    {id: 4, number: ["652", "625", "526", "562", "265", "256"]},
    {id: 5, number: ["498", "489", "984", "948", "849", "894"]},
    {id: 6, number: ["754", "745", "547", "574", "475", "457"]},
    {id: 7, number: ["669", "696", "696", "669", "966", "966"]},
    {id: 8, number: ["987", "978", "879", "897", "798", "789"]},
    {id: 9, number: ["156", "165", "561", "516", "615", "651"]},
    {id: 10, number: ["687", "678", "876", "867", "768", "786"]},
    {id: 11, number: ["238", "283", "382", "328", "823", "832"]},
    {id: 12, number: ["253", "235", "532", "523", "325", "352"]},
    {id: 13, number: ["369", "396", "693", "639", "936", "963"]},
    {id: 14, number: ["825", "852", "258", "285", "582", "528"]},
    {id: 15, number: ["363", "336", "633", "633", "336", "363"]},
    {id: 16, number: ["403", "430", "034", "043", "340", "304"]},
    {id: 17, number: ["427", "472", "274", "247", "742", "724"]},
    {id: 18, number: ["527", "572", "275", "257", "752", "725"]},
    {id: 19, number: ["452", "425", "524", "542", "245", "254"]},
    {id: 20, number: ["372", "327", "723", "732", "237", "273"]},
    {id: 21, number: ["421", "412", "214", "241", "142", "124"]},
    {id: 22, number: ["952", "925", "529", "592", "295", "259"]},
    {id: 23, number: ["954", "945", "549", "594", "495", "459"]},
    {id: 24, number: ["436", "463", "364", "346", "643", "634"]},
    {id: 25, number: ["506", "560", "065", "056", "650", "605"]},
    {id: 26, number: ["416", "461", "164", "146", "641", "614"]},
    {id: 27, number: ["536", "563", "365", "356", "653", "635"]},
    {id: 28, number: ["341", "314", "413", "431", "134", "143"]},
    {id: 29, number: ["928", "982", "289", "298", "892", "829"]},
    {id: 30, number: ["286", "268", "862", "826", "628", "682"]},
    {id: 31, number: ["712", "721", "127", "172", "271", "217"]},
    {id: 32, number: ["812", "821", "128", "182", "281", "218"]},
    {id: 33, number: ["539", "593", "395", "359", "953", "935"]},
    {id: 34, number: ["507", "570", "075", "057", "750", "705"]},
    {id: 35, number: ["453", "435", "534", "543", "345", "354"]},
    {id: 36, number: ["432", "423", "324", "342", "243", "234"]},
    {id: 37, number: ["502", "520", "025", "052", "250", "205"]},
    {id: 38, number: ["508", "580", "085", "058", "850", "805"]},
    {id: 39, number: ["125", "152", "251", "215", "512", "521"]},
    {id: 40, number: ["813", "831", "138", "183", "381", "318"]},
    {id: 41, number: ["154", "145", "541", "514", "415", "451"]},
    {id: 42, number: ["801", "810", "018", "081", "180", "108"]},
    {id: 43, number: ["803", "830", "038", "083", "380", "308"]},
    {id: 44, number: ["913", "931", "139", "193", "391", "319"]},
    {id: 45, number: ["883", "838", "838", "883", "388", "388"]},
    {id: 46, number: ["358", "385", "583", "538", "835", "853"]},
    {id: 47, number: ["011", "110", "101"]},
    {id: 48, number: ["411", "411", "114", "141", "141", "114"]},
    {id: 49, number: ["911", "911", "119", "191", "191", "119"]},
    {id: 50, number: ["611", "611", "116", "161", "161", "116"]},
    {id: 51, number: ["037", "073", "370", "307", "703", "730"]},
    {id: 52, number: ["023", "032", "230", "203", "302", "320"]},
    {id: 53, number: ["386", "368", "863", "836", "638", "683"]},
    {id: 54, number: ["384", "348", "843", "834", "438", "483"]},
    {id: 55, number: ["716", "761", "167", "176", "671", "617"]},
    {id: 56, number: ["350", "305", "503", "530", "035", "053"]},
    {id: 57, number: ["780", "708", "807", "870", "078", "087"]},
    {id: 58, number: ["150", "105", "501", "510", "015", "051"]},
    {id: 59, number: ["785", "758", "857", "875", "578", "587"]},
    {id: 60, number: ["957", "975", "579", "597", "795", "759"]},
    {id: 61, number: ["067", "076", "670", "607", "706", "760"]},
    {id: 62, number: ["998", "989", "989", "998", "899", "899"]},
    {id: 63, number: ["889", "898", "898", "889", "988", "988"]},
    {id: 64, number: ["517", "571", "175", "157", "751", "715"]},
    {id: 65, number: ["904", "940", "049", "094", "490", "409"]},
    {id: 66, number: ["864", "846", "648", "684", "486", "468"]},
    {id: 67, number: ["964", "946", "649", "694", "496", "469"]},
    {id: 68, number: ["479", "497", "794", "749", "947", "974"]},
    {id: 69, number: ["374", "347", "743", "734", "437", "473"]},
    {id: 70, number: ["454", "445", "544", "544", "445", "454"]},
    {id: 71, number: ["135", "153", "351", "315", "513", "531"]},
    {id: 72, number: ["567", "576", "675", "657", "756", "765"]},
    {id: 73, number: ["179", "197", "791", "719", "917", "971"]},
    {id: 74, number: ["731", "713", "317", "371", "173", "137"]},
    {id: 75, number: ["631", "613", "316", "361", "163", "136"]},
    {id: 76, number: ["841", "814", "418", "481", "184", "148"]},
    {id: 77, number: ["367", "376", "673", "637", "736", "763"]},
    {id: 78, number: ["735", "753", "357", "375", "573", "537"]},
    {id: 79, number: ["739", "793", "397", "379", "973", "937"]},
    {id: 80, number: ["353", "335", "533", "533", "335", "353"]},
    {id: 81, number: ["668", "686", "686", "668", "866", "866"]},
    {id: 82, number: ["816", "861", "168", "186", "681", "618"]},
    {id: 83, number: ["819", "891", "198", "189", "981", "918"]},
    {id: 84, number: ["589", "598", "895", "859", "958", "985"]},
    {id: 85, number: ["890", "809", "908", "980", "089", "098"]},
    {id: 86, number: ["741", "714", "417", "471", "174", "147"]},
    {id: 87, number: ["690", "609", "906", "960", "069", "096"]},
    {id: 88, number: ["842", "824", "428", "482", "284", "248"]},
    {id: 89, number: ["847", "874", "478", "487", "784", "748"]},
    {id: 90, number: ["728", "782", "287", "278", "872", "827"]},
    {id: 91, number: ["500", "500", "005", "050", "050", "005"]},
    {id: 92, number: ["738", "783", "387", "378", "873", "837"]},
    {id: 93, number: ["068", "086", "680", "608", "806", "860"]},
    {id: 94, number: ["194", "149", "941", "914", "419", "491"]},
    {id: 95, number: ["921", "912", "219", "291", "192", "129"]},
    {id: 96, number: ["267", "276", "672", "627", "726", "762"]},
    {id: 97, number: ["439", "493", "394", "349", "943", "934"]},
    {id: 98, number: ["844", "844", "448", "484", "484", "448"]},
    {id: 99, number: ["840", "804", "408", "480", "084", "048"]},
    {id: 100, number: ["239", "293", "392", "329", "923", "932"]},
    {id: 101, number: ["104", "140", "041", "014", "410", "401"]},
    {id: 102, number: ["986", "968", "869", "896", "698", "689"]},
    {id: 103, number: ["817", "871", "178", "187", "781", "718"]},
    {id: 104, number: ["246", "264", "462", "426", "624", "642"]},
    {id: 105, number: ["406", "460", "064", "046", "640", "604"]},
    {id: 106, number: ["851", "815", "518", "581", "185", "158"]},
    {id: 107, number: ["188", "188", "881", "818", "818", "881"]},
    {id: 108, number: ["856", "865", "568", "586", "685", "658"]},
    {id: 109, number: ["306", "360", "063", "036", "630", "603"]},
    {id: 110, number: ["079", "097", "790", "709", "907", "970"]},
    {id: 111, number: ["027", "072", "270", "207", "702", "720"]},
    {id: 112, number: ["951", "915", "519", "591", "195", "159"]},
    {id: 113, number: ["929", "992", "299", "299", "992", "929"]},
    {id: 114, number: ["956", "965", "569", "596", "695", "659"]},
    {id: 115, number: ["177", "177", "771", "717", "717", "771"]},
    {id: 116, number: ["117", "171", "171", "117", "711", "711"]},
    {id: 117, number: ["939", "993", "399", "399", "993", "939"]},
    {id: 118, number: ["155", "155", "551", "515", "515", "551"]},
    {id: 119, number: ["910", "901", "109", "190", "091", "019"]},
    {id: 120, number: ["216", "261", "162", "126", "621", "612"]},
    {id: 121, number: ["213", "231", "132", "123", "321", "312"]},
    {id: 122, number: ["209", "290", "092", "029", "920", "902"]},
    {id: 123, number: ["584", "548", "845", "854", "458", "485"]},
    {id: 124, number: ["585", "558", "855", "855", "558", "585"]},
    {id: 125, number: ["071", "017", "710", "701", "107", "170"]},
    {id: 126, number: ["983", "938", "839", "893", "398", "389"]},
    {id: 127, number: ["280", "208", "802", "820", "028", "082"]},
    {id: 128, number: ["390", "309", "903", "930", "039", "093"]},
    {id: 129, number: ["047", "074", "470", "407", "704", "740"]},
    {id: 130, number: ["916", "961", "169", "196", "691", "619"]},
    {id: 131, number: ["556", "565", "565", "556", "655", "655"]},
    {id: 132, number: ["404", "440", "044", "044", "440", "404"]},
    {id: 133, number: ["249", "294", "492", "429", "924", "942"]},
    {id: 134, number: ["062", "026", "620", "602", "206", "260"]},
    {id: 135, number: ["297", "279", "972", "927", "729", "792"]},
    {id: 136, number: ["204", "240", "042", "024", "420", "402"]},
    {id: 137, number: ["199", "199", "991", "919", "919", "991"]},
    {id: 138, number: ["559", "595", "595", "559", "955", "955"]},
    {id: 139, number: ["994", "949", "949", "994", "499", "499"]},
    {id: 140, number: ["474", "447", "744", "744", "447", "474"]},
    {id: 141, number: ["700", "700", "007", "070", "070", "007"]},
    {id: 142, number: ["764", "746", "647", "674", "476", "467"]},
    {id: 143, number: ["465", "456", "654", "645", "546", "564"]},
    {id: 144, number: ["699", "699", "996", "969", "969", "996"]},
    {id: 145, number: ["016", "061", "160", "106", "601", "610"]},
    {id: 146, number: ["688", "688", "886", "868", "868", "886"]},
    {id: 147, number: ["656", "665", "566", "566", "665", "656"]},
    {id: 148, number: ["767", "776", "677", "677", "776", "767"]},
    {id: 149, number: ["636", "663", "366", "366", "663", "636"]},
    {id: 150, number: ["121", "112", "211", "211", "112", "121"]},
    {id: 151, number: ["504", "540", "045", "054", "450", "405"]},
    {id: 152, number: ["263", "236", "632", "623", "326", "362"]},
    {id: 153, number: ["769", "796", "697", "679", "976", "967"]},
    {id: 154, number: ["962", "926", "629", "692", "296", "269"]},
    {id: 155, number: ["151", "115", "511", "511", "115", "151"]},
    {id: 156, number: ["113", "131", "131", "113", "311", "311"]},
    {id: 157, number: ["455", "455", "554", "545", "545", "554"]},
    {id: 158, number: ["799", "799", "997", "979", "979", "997"]},
    {id: 159, number: ["667", "676", "676", "667", "766", "766"]},
    {id: 160, number: ["077", "077", "770", "707", "707", "770"]},
    {id: 161, number: ["377", "377", "773", "737", "737", "773"]},
    {id: 162, number: ["122", "122", "221", "212", "212", "221"]},
    {id: 163, number: ["343", "334", "433", "433", "334", "343"]},
    {id: 164, number: ["622", "622", "226", "262", "262", "226"]},
    {id: 165, number: ["600", "600", "006", "060", "060", "006"]},
    {id: 166, number: ["088", "088", "880", "808", "808", "880"]},
    {id: 167, number: ["313", "331", "133", "133", "331", "313"]},
    {id: 168, number: ["242", "224", "422", "422", "224", "242"]},
    {id: 169, number: ["434", "443", "344", "344", "443", "434"]},
    {id: 170, number: ["477", "477", "774", "747", "747", "774"]},
    {id: 171, number: ["722", "722", "227", "272", "272", "227"]},
    {id: 172, number: ["111"]},
    {id: 173, number: ["660", "606", "606", "660", "066", "066"]},
    {id: 174, number: ["449", "494", "494", "449", "944", "944"]},
    {id: 175, number: ["575", "557", "755", "755", "557", "575"]},
    {id: 176, number: ["757", "775", "577", "577", "775", "757"]},
    {id: 177, number: ["885", "858", "858", "885", "588", "588"]},
    {id: 178, number: ["552", "525", "525", "552", "255", "255"]},
    {id: 179, number: ["330", "303", "303", "330", "033", "033"]},
    {id: 180, number: ["220", "202", "202", "220", "022", "022"]},
    {id: 181, number: ["414", "441", "144", "144", "441", "414"]},
    {id: 182, number: ["995", "959", "959", "995", "599", "599"]},
    {id: 183, number: ["848", "884", "488", "488", "884", "848"]},
    {id: 184, number: ["662", "626", "626", "662", "266", "266"]},
    {id: 185, number: ["788", "788", "887", "878", "878", "887"]},
    {id: 186, number: ["797", "779", "977", "977", "779", "797"]},
    {id: 187, number: ["446", "464", "464", "446", "644", "644"]},
    {id: 188, number: ["181", "118", "811", "811", "118", "181"]},
    {id: 189, number: ["292", "229", "922", "922", "229", "292"]},
    {id: 190, number: ["225", "252", "252", "225", "522", "522"]},
    {id: 191, number: ["828", "882", "288", "288", "882", "828"]},
    {id: 192, number: ["322", "322", "223", "232", "232", "223"]},
    {id: 193, number: ["424", "442", "244", "244", "442", "424"]},
    {id: 194, number: ["337", "373", "373", "337", "733", "733"]},
    {id: 195, number: ["393", "339", "933", "933", "339", "393"]},
    {id: 196, number: ["355", "355", "553", "535", "535", "553"]},
    {id: 197, number: ["383", "338", "833", "833", "338", "383"]},
    {id: 198, number: ["616", "661", "166", "166", "661", "616"]},
    {id: 199, number: ["100", "100", "001", "010", "010", "001"]},
    {id: 200, number: ["040", "004", "400", "400", "004", "040"]},
    {id: 201, number: ["030", "003", "300", "300", "003", "030"]},
    {id: 202, number: ["233", "233", "332", "323", "323", "332"]},
    {id: 203, number: ["787", "778", "877", "877", "778", "787"]},
    {id: 204, number: ["282", "228", "822", "822", "228", "282"]},
    {id: 205, number: ["009", "090", "090", "009", "900", "900"]},
    {id: 206, number: ["200", "200", "002", "020", "020", "002"]},
    {id: 207, number: ["909", "990", "099", "099", "990", "909"]},
    {id: 208, number: ["000"]},
    {id: 209, number: ["111"]},
    {id: 210, number: ["222"]},
    {id: 211, number: ["333"]},
    {id: 212, number: ["444"]},
    {id: 213, number: ["555"]},
    {id: 214, number: ["666"]},
    {id: 215, number: ["777"]},
    {id: 216, number: ["888"]},
    {id: 217, number: ["999"]},
    {id: 218, number: ["008", "080", "080", "008", "800", "800"]},
    {id: 219, number: ["277", "277", "772", "727", "727", "772"]},
    {id: 220, number: ["466", "466", "664", "646", "646", "664"]},
    {id: 221, number: ["055", "055", "550", "505", "505", "550"]}
];

export const getBackNumber3 = (number) => {
    let data = _.find(tod3, function (mode) {
        return mode.number.includes(number)
    });
    return data ? data.number : null;
}

export const getBackNumber2 = (number) => {
    let splitString = number.split("");
    let reverseArray = splitString.reverse();
    return [number, reverseArray.join("")];
}

export const swipeFront = (number) => {
    return [`${number}0`, `${number}1`, `${number}2`, `${number}3`, `${number}4`, `${number}5`, `${number}6`, `${number}7`, `${number}8`, `${number}9`];
}

export const swipeBack = (number) => {
    return [`0${number}`, `1${number}`, `2${number}`, `3${number}`, `4${number}`, `5${number}`, `6${number}`, `7${number}`, `8${number}`, `9${number}`];
}

export const nineteenDoor = (number) => {
    return _.uniq([...swipeFront(number), ...swipeBack(number)]);
}
