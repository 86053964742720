import PageHeader from "../../components/PageHeader";
import { useEffect } from "react";
import {numberWithCommas} from "../../utils/math";
import {useSelector, useDispatch} from "react-redux";
import {useState} from "react";
import { createqrcode, getbalance } from '../../store/user'
import {useNavigate} from "react-router-dom";
//import { useEffect } from "react";
import ModelApprove from '../../components/ModalQr'
import NavBar from '../../components/NavBar'
import Modal from '../../components/Modal'
import { Spinner } from "react-bootstrap";

function TunsamaiSummary() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {total, cart} = useSelector(state => state.tunsamai);
    const { gameitem, isLoadingLogin } = useSelector(state => state.user);
    const [payments] = useState([
        {id: 1, image: "/images/icon/credit.png", name: "Credit"},
       // {id: 2, image: "/images/icon/one.png", name: "BCEL One"},
       // {id: 3, image: "/images/icon/umoney.png", name: "U-money"}
    ])

    const {item} = useSelector(state => state.user);
    useEffect(()=> {
        dispatch(getbalance({}))
    },[])
    const [show, setShow] = useState(false);
    const [pay, setPay] = useState('')

    const showqr = (id) => {
        setPay(id)
        let bank = 1
        if(id === 1) {
            bank = 'credit'
        }
        if(id === 2) {
            bank = 'bcel'
        }
        if(id === 3) {
            bank = 'umoney'
        }
        if(isLoadingLogin === false) {
            const checktype = (type, typename) => {
                if(type.length === 1) {
                    return '1'
                } else if(typename === '2lower') {
                    return '2lower'
                } else if(type.length === 2) {
                    return '2'
                } else if(type.length === 3) {
                    return '3'
                } else if(type.length === 4) {
                    return '4'
                } else if(type.length === 5) {
                    return '5'
                } else if(type.length === 6) {
                    return '6'
                }
            }
            const newitems = cart.map(x=> ({...x,type:checktype(`${x.number}`,x.type)}))
            if(item?.balance < total && id === 1) {
                setShow(true)
            } else {
                dispatch(createqrcode({amount:total, cart:newitems, huaysubId:gameitem?.id, bankdata:bank, navigate}))
            }
            
        }
       
    }

    return (
        <div className={'container withdraw'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo logoSize={'80'} backPath={'/lotto/tunsamai'}/>
                    {/* end page title */}

                    {/* cart */}
                    <div className={'mb-5'} data-aos="fade-up">
                        <div className={'text-primary h5'}>
                            ສະຫຼຸບລາຍຊື່
                        </div>

                        <div className={'card card-green mb-5'}>
                            <div className={'card-body'}>

                                {/* cart */}
                                {cart.length > 0 && <div className={'cart mb-3'}>
                                    <div className={'d-flex justify-content-between text-secondary mb-1'}>
                                        <div>ຕົວເລກທີ່ທ່ານເລືອກ</div>
                                        <div>ຈໍານວນເງິນ</div>
                                    </div>

                                    {cart.map((data, index) => {
                                        return <div className={'list'} key={`${data.name}_${index}`}>
                                            <div className={'row align-items-center'}>
                                                <div className={'col-auto'}>
                                                    <img className="icon-animal" alt={'animal'}
                                                         src={`${process.env.PUBLIC_URL}/images/animal/${data.image}.png`}/>
                                                </div>

                                                <div className={'col'}>
                                                    <div className={'d-flex gap-3'}>
                                                        <div>{data.number}</div>
                                                    </div>
                                                </div>

                                                <div className={'col-auto d-flex align-items-center text-end'}>
                                                <span
                                                    className={'me-1'}>  {numberWithCommas(data.amount)} </span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>}
                                {/* end cart */}

                                <div className={'d-flex justify-content-between align-items-end mb-2'}>
                                    <div className={'text-secondary'}>
                                        ຈໍານວນທັງຫມົດທີ່ຈະຈ່າຍ
                                    </div>
                                    <div className={'h5 mb-0 lh-1 text-primary'}>
                                        {numberWithCommas(total)}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end cart */}

                    {/* payments  */}
                    <div className={'mb-5'}>
                        <div className={'text-primary h5'}>
                            ເລືອກວິທີການຊໍາລະເງິນ
                        </div>

                        {payments.map(data => {
                            return <div className={'card card-green mb-3'} key={`${data.id}`}>
                                <div className={'py-2 px-3'}>
                                    <div className={'d-flex gap-3 justify-content-between align-items-center'}>
                                        <div className={'d-flex gap-3 align-items-center'}>
                                            <img className="img-fluid img-payment"
                                                 src={`${process.env.PUBLIC_URL}${data.image}`}
                                                 alt={data.name}/>
                                            <div>
                                                <div className={'text-secondary'}>
                                                    ຈ່າຍຜ່ານ
                                                </div>
                                                <div className={'text-primary'}>
                                                    {data.id !== 1 && data.name}
                                                    {
                                                        data.id === 1 && <span>ກະເປົາເງິນ : {numberWithCommas(item?.balance || 0)}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-end'}>
                                            {
                                                isLoadingLogin === true && pay === data.id ? <button className={'btn btn-primary text-decoration-none'}  ><Spinner /></button> : <button className={'btn btn-primary text-decoration-none'} onClick={()=>showqr(data.id)} >ເລືອກ</button>
                                            }
                                            
                                            {
                                                /*
<NavLink to={'/lotto/tunsamai/payment'}
                                                     className={'btn btn-primary text-decoration-none'}>
                                                เลือก
                                            </NavLink>
                                                */
                                            }
                                
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })}
                    </div>
                    {/* end payments  */}
                </div>
            </div>
            <ModelApprove show={false} onHide={()=>{}} />
            <NavBar />
            <Modal show={show} msg='ຍອດເງິນບໍ່ພຽງພໍທີ່ຈະຈ່າຍ.' onHide={()=>setShow(false)} />
        </div>
    );
}

export default TunsamaiSummary;
