import PageHeader from "../../components/PageHeader";
import {numberWithCommas} from "../../utils/math";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { getbookhuay, rejectbill } from '../../store/user'
import  Navbar from '../../components/NavBar'
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import * as _ from "underscore";
import moment from "moment";

function MyBill() {

    const checkimg = (q) => {
        const bill = [
            {id: 1, image: "01", name: "ປານ້ອຍ", numbers: ['01', '41', '81']},
            {id: 2, image: "02", name: "ຫອຍ", numbers: ['02', '42', '82']},
            {id: 3, image: "03", name: "ຫ່ານ", numbers: ['03', '43', '83']},
            {id: 4, image: "04", name: "ນົກຍູງ", numbers: ['04', '44', '84']},
            {id: 5, image: "05", name: "ສິງ", numbers: ['05', '45', '85']},
            {id: 6, image: "06", name: "ເສືອ", numbers: ['06', '46', '86']},
            {id: 7, image: "07", name: "ຫມູ", numbers: ['07', '47', '87']},
            {id: 8, image: "08", name: "ກະຕ່າຍ", numbers: ['08', '48', '88']},
            {id: 9, image: "09", name: "ຄວາຍ", numbers: ['09', '49', '89']},
            {id: 10, image: "10", name: "ນາກນ້ຳ", numbers: ['10', '50', '90']},
            {id: 11, image: "11", name: "ໝາ", numbers: ['11', '51', '91']},
            {id: 12, image: "12", name: "ມ້າ", numbers: ['12', '52', '92']},
            {id: 13, image: "13", name: "ຊ້າງ", numbers: ['13', '53', '93']},
            {id: 14, image: "14", name: "ແມວບ້ານ", numbers: ['14', '54', '94']},
            {id: 15, image: "15", name: "ຫນູ", numbers: ['15', '55', '95']},
            {id: 16, image: "16", name: "ເຜິ້ງ", numbers: ['16', '56', '96']},
            {id: 17, image: "17", name: "ນົກຍາງ", numbers: ['17', '57', '97']},
            {id: 18, image: "18", name: "ແມວປ່າ", numbers: ['18', '58', '98']},
            {id: 19, image: "19", name: "ກະເບື້ອ", numbers: ['19', '59', '99']},
            {id: 20, image: "20", name: "ຂີ້ເຂັບ", numbers: ['20', '60', '00']},
            {id: 21, image: "21", name: "ນົກແອ່ນ", numbers: ['21', '61']},
            {id: 22, image: "22", name: "ນົກກາງແກ", numbers: ['22', '62']},
            {id: 23, image: "23", name: "ລິງ", numbers: ['23', '63']},
            {id: 24, image: "24", name: "ກົບ", numbers: ['24', '64']},
            {id: 25, image: "25", name: "ແຫຼວ", numbers: ['25', '65']},
            {id: 26, image: "26", name: "ນາກບິນ", numbers: ['26', '66']},
            {id: 27, image: "27", name: "ເຕົ່າ", numbers: ['27', '67']},
            {id: 28, image: "28", name: "ໄກ່", numbers: ['28', '68']},
            {id: 29, image: "29", name: "ອ່ຽນ", numbers: ['29', '69']},
            {id: 30, image: "30", name: "ປາໃຫຍ່", numbers: ['30', '70']},
            {id: 31, image: "31", name: "ກຸ້ງ", numbers: ['31', '71']},
            {id: 32, image: "32", name: "ງູ", numbers: ['32', '72']},
            {id: 33, image: "33", name: "ແມງມຸມ", numbers: ['33', '73']},
            {id: 34, image: "34", name: "ກວາງ", numbers: ['34', '74']},
            {id: 35, image: "35", name: "ແບ້", numbers: ['35', '75']},
            {id: 36, image: "36", name: "ເຫງັນ", numbers: ['36', '76']},
            {id: 37, image: "37", name: "ຫຼິ່ນ", numbers: ['37', '77']},
            {id: 38, image: "38", name: "ເໝັ້ນ", numbers: ['38', '78']},
            {id: 39, image: "39", name: "ກະປູ", numbers: ['39', '79']},
            {id: 40, image: "40", name: "ນົກອິນຊີ", numbers: ['40', '80']},
        ] 

        const newnumber = `${q}`
        let nnumber = `${q}`
        if(newnumber.length === 1) {
            nnumber = `0${q}`
        }else if(newnumber.length > 2) {
            nnumber = newnumber.slice(-2)
        }else {
            nnumber = `${q}`
        }

        const {image}  = _.find(bill, function (data) {
            return data.numbers.includes(nnumber);
        });

        return image
    }

    const dispatch = useDispatch()
    const { roundId } = useParams()
    const { huayitem, accountdeposit, isLoadingBill, isLoadingBtn } = useSelector(state=>state.user)

    useEffect(()=> {
        dispatch(getbookhuay({roundId}))
    }, [roundId])

    const rejecthuay = () => {
        Swal.fire({
            html: `<div class=""> ເຈົ້າແນ່ໃຈບໍ່ວ່າຕ້ອງການ ຍົກເລີກໃບບິນ  ? </div>`,
            icon: "warning",
            showCancelButton: true,
            focusConfirm: false,
            focusCancel: false,
            reverseButtons: true,
            confirmButtonText: "ຢືນຢັນ",
            cancelButtonText: "ປິດ",
            customClass: {
                confirmButton: "bg-danger",
                cancelButton: "bg-secondary"
            }
        }).then(async (result) => {
            if (result.value) {
                if(isLoadingBtn === false) {
                dispatch(rejectbill({billid:roundId, huaysubId:huayitem?.huaysubId}))
                }
            }
        })
    }
    return (
        <div className={'container my-bill'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo backPath={'/bill/huay-thai'}/>
                    {/* end page title */}

                    {isLoadingBill === true ? <div className="text-center"><Spinner /></div> :  ''}
                    {/* round */}
                               
                    <div className={'card card-green mb-3'} data-aos="fade-up">
                        <div className={'card-body'}>
                        <div className={'d-flex justify-content-center text-secondary'}>
                                <div className="text-center dimg">
                                    <img src={`/images/th.png`} />
                                    <div>{huayitem?.name}</div>
                                    </div>
                        </div>
                            <div className={'d-flex justify-content-between text-secondary'}>
                                <div>
                                    <div>
                                         ງວດທີ່
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                     {huayitem?.no}
                                    </div>

                                </div>
                                <div className={'text-end'}>
                                    <div>
                                        ວັນທີ່
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                        {moment(huayitem?.createdAt).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                            </div>
                            <div className={'d-flex justify-content-between text-secondary boderline'}>
                                <div>
                                   
                                    <div className={'text-primary h5 mb-0'}>
                                    ຜົນລາງວັນ
                                    </div>

                                </div>
                                <div className={'text-end'}>
                                   
                                    <div className={'text-primary h5 mb-0'}>
                                        {huayitem?.bet6result?.length === 0 && '-'}
                                    
                                    </div>
                                    {
                                        huayitem?.bet6result?.length > 0 && <div className={'text-primary h5 mb-0'}>
                                        <span className="ball">{huayitem?.bet6result[0]}</span>
                                        <span className="ball">{huayitem?.bet6result[1]}</span>
                                        <span className="ball">{huayitem?.bet6result[2]}</span>
                                        <span className="ball">{huayitem?.bet6result[3]}</span>
                                        <span className="ball">{huayitem?.bet6result[4]}</span>
                                        <span className="ball">{huayitem?.bet6result[5]}</span>
                                        </div>
                                    }
                                    <div className="d-block">&nbsp;</div>
                                    {
                                        huayitem?.bet2lower?.length > 0 && <span><div className={'text-primary h5 mb-0'}>
                                        <span className="lt2x">2ໂຕລ່າງ </span>
                                        <span className="ball">{huayitem?.bet2lower[0]}</span>
                                        <span className="ball">{huayitem?.bet2lower[1]}</span>
                                       
                                        </div></span>
                                    }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end round */}

                    {/* summary */}
                    <div className={'card card-green mb-5'} data-aos="fade-up">
                        <div className={'card-body'}>
                            <div className="d-flex justify-content-between text-secondary mb-1">
                                <div>ເລກທີ່ທ່ານເລືອກ</div>
                                <div>ອັດຕາຈ່າຍ</div>
                                <div>ຈຳນວນເງິນ</div>
                            </div>

                            <div className={'mb-4 cart'}>
                                {huayitem?.dtl &&  huayitem?.dtl.map((data, index) => {
                                    return <div className={'list'} key={`${data._id}_${index}`}>
                                        <div className={'row align-items-center'}>
                                            <div className={'col-auto'}>
                                                <img className="icon-animal" alt={'animal'}
                                                     src={`${process.env.PUBLIC_URL}/images/animal/${checkimg(data?.number)}.png`}/>
                                            </div>

                                            <div className={'col'}>
                                                <div className={'d-flex gap-3'}>
                                                    {
                                                        data?.reward > 0 ? <div className="number overbill">
                                                        {data?.number}{data?.type === '2lower' && <span className="lt2">ລ່າງ</span>}
                                                        </div> : <div className="x">
                                                {data?.number}{data?.type === '2lower' && <span className="lt2">ລ່າງ</span>}
                                                </div>
                                                    }
                                                
                                                    
                                                </div>
                                            </div>
                                            <div className={'col'}>
                                                <div className={'d-flex gap-3'}>
            
                                                    {numberWithCommas(data?.rate)}
                                                </div>
                                            </div>

                                            <div className={'col-auto d-flex align-items-center text-end'}>
                                                <span className={'me-1'}>  {numberWithCommas(data?.amount)} </span>
                                            </div>
                                        </div>
                                    </div>
                                })}

                                {/* total */}
                                <div className={'mt-3 d-flex justify-content-between'}>
                                    <div className={'text-secondary'}>
                                        ຈຳນວນເງິນ:
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                        {numberWithCommas(huayitem.amount || 0)}
                                    </div>
                                </div>
                                {
                                    huayitem.reward > 0 && <div className={'mt-3 d-flex justify-content-between'}>
                                    <div className={'text-secondary'}>
                                        ໄດ້ລາງວັນ:
                                    </div>
                                    <div className={'text-primary h5 mb-0'}>
                                       <div className="number overbill">{numberWithCommas(huayitem.reward || 0)}</div> 
                                    </div>
                                </div>
                                }
                                

                                {/* end  total */}
                            </div>


                            {/* info */}
                            {
                                huayitem.status === 5 && accountdeposit.status !==3 &&  <div className={'card card-dark'}>
                                <div className={'card-body text-center text-danger'}>
                                   รอยืนยันยอดฝาก
                                </div>
                            </div> 
                            }

                            {
                                huayitem.billcode &&  <div className={'card card-pink'}>
                                <div className={'card-body'}>
                                    ໝາຍເລກໃບບິນ: {huayitem.billcode} <br/>
                                    ຈ່າຍຜ່ານ: {huayitem.note} <br/>
                                    ວັນທີ: {moment(huayitem.createdAt).format("DD/MM/YYYY HH:mm")}
                                </div>
                            </div>
                            }

{
                                huayitem?.status === 3 && <button className="btn btn-primary btn-block mt-3" onClick={()=>rejecthuay()}>
                                    ຍົກເລີກໃບບິນ
                                    {isLoadingBtn === true && <Spinner />}
                                    </button>
                            }

                         
                           
                            {/* end info */}

                        </div>
                    </div>
                    {/* end summary */}
                </div>
            </div>
        <Navbar />
        </div>
    );
}

export default MyBill;
