import {NavLink, useLocation} from "react-router-dom";

function CustomNavLinkWithIcon({to, activeIcon, inActiveIcon, text}) {
    const {pathname} = useLocation();

    return (
        <NavLink to={to}>
            <img className="img-fluid menu-icon" src={pathname === to ? inActiveIcon : activeIcon} alt={'menu'}/>
            <div className={'menu-name'}>
                {text}
            </div>
        </NavLink>
    );
}

export default CustomNavLinkWithIcon;
