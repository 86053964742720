import {Link} from "react-router-dom";
import SwiperBanner from "../components/SwiperBanner";
import PageHeader from "../components/PageHeader";
import {useSelector, useDispatch} from "react-redux";
import {numberWithCommas} from "../utils/math";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { getbalance, logout, bannerlist, bannerpopup } from "../store/user";
import { WEBCASINO, WEBNAME } from "../store/config";
import Popup from '../components/Popup'
import Popfix from '../components/PopupFix'

function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const {item, isloadingbannerp, showpop, nologin, idcookie} = useSelector(state => state.user);
    useEffect(()=> {
        dispatch(getbalance({}))
        dispatch(bannerlist({}))
       
    },[])

    useMemo(()=> {
        if(isloadingbannerp === false) {
            dispatch(bannerpopup({type:2,id:idcookie}))
        }
    }, [])
    const loadbanner =()=> {
        if(isloadingbannerp === false) {
        dispatch(bannerpopup({type:3,id:idcookie}))
        }
    }
    const gopath = (path) => {
        navigate(path)
    }
    const logoutPage = () => {
        dispatch(logout())
    }
    return (
        <div className={'container home'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto'} style={{maxWidth: '450px'}}>
                    {
                        nologin === '1' && <div className={'text-center d-flex justify-content-between'}>
                        <NavLink to={'/login'}>
                       <div className={'w-100 btn btn-primary btn-xs'}>ເຂົ້າສູ່ລະບົບ</div>
                                    </NavLink>
                                    <NavLink to={'/register'}>
                                        <div className={'w-100 btn btn-danger btn-xs'}>ສະຫມັກ ສະມາຊິກ</div>
                                    </NavLink>
                        </div>
                    }

                    {
                        nologin === '1' && <PageHeader logo 
                        
                         />
                    }

                    {
                        nologin === '2' && <PageHeader logo logOut='/sss' logoutPage={logoutPage}
                        
                         />
                    }
                
               

                    {/* swiper */}
                    <div className={'mb-4'}>
                        <SwiperBanner/>
                    </div>
                    {/* end swiper */}


                    {/* profile */}
                    <div className={'mb-2'}>
                        <div className={'card card-green mb-2 profile'}>
                            <div className={'card-body'}>

                                <div className={'d-flex mb-2 justify-content-between align-items-center'}>
                                    <div>
                                        <img
                                            alt={'mobile'}
                                            className={'me-2 icon'}
                                            src={`${process.env.PUBLIC_URL}/images/icon/mobile-phone.png`}/>
                                        <span>{item.mobile}</span>
                                    </div>

                                    <div>
                                    <img
                                            alt={'mobile'}
                                            className={'me-2 icon'}
                                            src={`${process.env.PUBLIC_URL}/images/bank-account.png`}/>
                                     {item.accno} <img alt={'logo'}
                                        className={'ms-2 bg-white rounded-5 border-white one-logo'}
                                        src={`${process.env.PUBLIC_URL}/images/logo_one.png`}/>
                                    </div>
                                </div>

                                <div className={'d-flex gap-3 justify-content-center align-items-end'}>
                                ຍອດເຄດິດ ໃນຢຸດເຊີ້
                                    <h3 className="mb-0 text-primary ">
                                        {numberWithCommas(item.balance)}
                                    </h3>
                                    ກີບ
                                </div>

                                <hr className={'my-3 hr-secondary'}/>

                                <div className={'d-flex gap-3 justify-content-center align-items-end'}>
                                    <Link onClick={()=>window.location = '/deposit'}>
                                        <div className={'btn btn-primary'}>ຝາກເງິນ</div>
                                    </Link>

                                    <Link to={'/withdraw'}>
                                        <div className={'btn btn-danger'}>ຖອນເງິນ</div>
                                    </Link>
                                </div>
                                <div className={'d-flex pt-2 gap-3 justify-content-center align-items-end'}>
                                <Link to={'/history'}>
                                        <div className={'btn btn-outline-primary'}> <img src={`/images/file.png`} style={{maxHeight:20, paddingRight:20}} />ລາຍການເຄດິດ</div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end profile */}


                    {/* banner */}
                    <div className={'d-flex gap-3 mb-2 align-items-center'} >
                        <div className={'col'}>
                            <div className={'p-2'} onClick={()=>gopath('/lotto')}>
                                <img className="img-fluid rounded " alt={'banner'}
                                     src={`${process.env.PUBLIC_URL}/images/${WEBNAME}/play.png`}/>
                            </div>
                        </div>
                        <div className={'col'}>
                            <img className="img-fluid rounded  mb-4" onClick={()=>gopath('/affiliate')}  alt={'affiliate'}
                                 src={`${process.env.PUBLIC_URL}/images/${WEBNAME}/aff2.png`}/>
                                  <Link to={`${WEBCASINO}`}>
                            <img className="img-fluid rounded" alt={'casino'}
                                 src={`${process.env.PUBLIC_URL}/images/${WEBNAME}/casino.png`}/>
                                 </Link>
                        </div>
                    </div>
                    {/* end banner */}


                    {/* affiliate */}
                   
                    {/* end affiliate */}

                     {/* profile */}
                     <div className={'mb-2'}>
                        <div className={'card card-green mb-2 profile'} >
                            <div className={'card-bodyx'}>

                            <img onClick={()=>gopath('/affiliate')} className="img-fluid img-affiliate" alt={'affiliate'}
                                         src={`${process.env.PUBLIC_URL}/images/${WEBNAME}/aff.png`}/>

                            </div>
                        </div>
                    </div>
                    {/* end profile */}

                </div>
            </div>
            <Popup  show={showpop} onHide={()=>loadbanner()} />
            
            <div className="linefixed" onClick={()=>navigate('/contacts')}><img width="150" height="231" src="/images/thaiman.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/thaiman.png 150w, /images/thaiman.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
    );
}

export default Home;
