import {useState, useEffect, useMemo} from "react";
import {colorUserLevel} from "../utils/text";
import {useSelector, useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import {numberWithCommas} from "../utils/math";
import { getaffdata, withdrawaff, checkaffiliate } from "../store/user";
import QRCode from "qrcode";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Spinner } from "react-bootstrap";
function Affiliate() {
    const [menu, setMenu] = useState('overview');
    const { isLoading, affitem, } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getaffdata({}))
    },[])
    const navigate = useNavigate()
    const WithdrawAmount = () => {
        if(isLoading === false && parseInt(affitem?.sumAll || 0) >= 10000){
            dispatch(withdrawaff())
        }
    }

    const [copied, setcopied] = useState(false);

    const [qrcode, setQrcode] = useState('')
    const url = affitem?.afflink
    const genqr = () => {
        QRCode.toDataURL(url, (err, url )=> {
            if(err) return console.log(err)
            setQrcode(url)
        })
    }

    useMemo(()=> {
        genqr()
    }, [affitem?.afflink])
  const setCopyall = () => {
    setcopied(true);
  };


    const [affiliate] = useState([
        {id: 1, image: "/images/avatar/01.png", mobile_no: "0943348XXXX", level: 1, date: "2 ປີ 24 ວັນ"},
        {id: 2, image: "/images/avatar/02.png", mobile_no: "0943348XXXX", level: 1, date: "36  ວັນ"},
        {id: 3, image: "/images/avatar/03.png", mobile_no: "0943348XXXX", level: 2, date: "25  ວັນ"},
        {id: 4, image: "/images/avatar/04.png", mobile_no: "0943348XXXX", level: 1, date: "2 ປີ 24 ວັນ"},
    ]);


    const [history] = useState([
        {id: 1, date: "23/06/2023", amount: '10,000', bank: "BCEL One"},
        {id: 2, date: "23/06/2023", amount: '5,000', bank: "BCEL One"},
        {id: 3, date: "23/06/2023", amount: '200,000', bank: "BCEL One"},
        {id: 4, date: "23/06/2023", amount: '10,000', bank: "BCEL One"},
        {id: 5, date: "23/06/2023", amount: '5,000', bank: "BCEL One"},
        {id: 6, date: "23/06/2023", amount: '200,000', bank: "BCEL One"},
    ]);

    return (<div className={'container affiliate'}>
        <div className={'row justify-content-center min-vh-100 py-4'}>
            <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                {/* page title */}
                <PageHeader logo/>
                {/* end page title */}

                {/* affiliate id */}
                <div className={'card card-green mb-5'} data-aos="fade-up">

              

                    <div className={'card-body'}>
                        <div className={'text-center'}>
                        ລິ້ງລ້ຳລວຍ ຮັບ 6%, 1% ຈາກຍອດຫລິ້ນ
                        </div>
                        
                        <hr/>
                        <div className="porela containlinkfriend text-center">
                                <label className={'mb-3'}>ລິ້ງລ້ຳລວຍ&nbsp;</label>
                               
                                <CopyToClipboard
              text={`${affitem?.afflink}`}
              onCopy={() => setCopyall()}
            >
                <button className="btn btnback" >ຄລິກລິ້ງ</button>
               
                 
                </CopyToClipboard>
               
                <input type="text" id="friendlink" className={`${copied ? `loginform01 copylink1ac`:`loginform01 copylink1`}`}
                                       disabled value={affitem?.afflink}/>
                            </div>

                            <div className="text-center">QRCode ລິ້ງລ້ຳລວຍ</div>
                            <div className="text-center"> <img src={qrcode} /></div>
                            <div className="text-center pt-1"><a className="btn btnback" href={qrcode} download='aff_qr.png' >ດາວໂຫຼດ</a></div>
                            
                        <ul>
                           
                            <li>
                            ສົ່ງຕໍ່ລິ້ງລ້ຳລວຍຂອງເຮົາໃຫ້ໝູ່ສະໝັກຮັບຄອມມິດຊັ້ນຂອງໝູ່ <span className={'text-primary'}> 6%</span>
                            </li>
                            <li>
                            ແລະຄ່າຄອມມິດຊັ້ນຂອງໝູ່ ຂອງໝູ່ອີກ<span className={'text-primary'}> 1%</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* end affiliate id */}

                {/* affiliate menu */}
                <div className={'row mb-5 affiliate-menu'}>
                    <div className={'col'} onClick={() => setMenu('overview')}>
                        <div className={`menu-item ${menu === 'overview' ? 'active' : ""} `}>
                        ພາບລວມ
                        </div>
                    </div>
                    <div className={'col'} onClick={() => setMenu('affiliate')}>
                        <div className={`menu-item ${menu === 'affiliate' ? 'active' : ""} `}>
                        ສະມາຊິກ
                        </div>
                    </div>
                    <div className={'col'} onClick={() => setMenu('history')}>
                        <div className={`menu-item ${menu === 'history' ? 'active' : ""} `}>
                        ຖອນລາຍໄດ້
                        </div>
                    </div>
                </div>
                {/* end affiliate menu */}

                {/* overview */}
                {menu === 'overview' && <div className={'card card-green mb-5 overview'} >
                    <div className={'card-body'}>
                        <h5 className={'text-center mb-0'}>
                        ລາຍໄດ້ທັງໝົດຂອງທ່ານແມ່ນ (ກີບ)
                        </h5>
                        <h3 className={'text-center text-primary mb-3'}>
                            {numberWithCommas(affitem?.sumAll || 0)}
                        </h3>

                        <div className="affpay">
                        ຖອນລາຍໄດ້ຂັ້ນຕ່ຳ 10,000 ກີບ ລູກຄ້າກົດຄຳວ່າ ຖອນລາຍໄດ້ທັງຫມົດ ເງິນ%ຂອງລູກຄ້າແມ່ນຈະຖືກໂອນເຂົ້າບັນຊີທະນາຄານຂອງລູກຄ້າເລີຍຈ້າວ
                        </div>


                        <div className={'row text-center h-100'}>
                            <div className={'col  border-end border-secondary'}>
                            ຍອດແນະນຳ (6%)
                                <h4 className={'text-primary'}>
                                    {numberWithCommas(affitem?.sumLevel1 || 0)}
                                </h4>
                            </div>

                            <div className={'col'}>
                            ຍອດແນະນຳ ຂັ້ນທີ 2 (1%)
                                <h4 className={'text-primary'}>
                                {numberWithCommas(affitem?.sumLevel2 || 0)}
                                </h4>
                            </div>

                        </div>


                        <div className={'row justify-content-between pb-1'}>
                            <div className={'col'}>
                                <div className={'d-flex align-items-center'}>
                                    <img className="img-fluid me-2" alt={'account'}
                                         src={`${process.env.PUBLIC_URL}/images/icon/supervisor_account.svg`}/>
                                    <div>
                                    ສະມາຊິກຂັ້ນທີ 1
                                    </div>
                                </div>
                            </div>
                            <div className={'col-auto'}> {numberWithCommas(affitem?.countLevel1 || 0)} ຄົນ</div>
                        </div>

                        <hr className={'my-1'}/>

                        <div className={'row justify-content-between pb-1'}>
                            <div className={'col'}>
                                <div className={'d-flex align-items-center'}>
                                    <img className="img-fluid me-2" alt={'account'}
                                         src={`${process.env.PUBLIC_URL}/images/icon/supervisor_account.svg`}/>
                                    <div>
                                    ສະມາຊິກຂັ້ນທີ 2
                                    </div>
                                </div>
                            </div>
                            <div className={'col-auto'}> {numberWithCommas(affitem?.countLevel2 || 0)} ຄົນ</div>
                        </div>
                    </div>
                </div>}
                {/* end overview */}

                {/* member */}
                {menu === 'affiliate' && <div className={'card card-green mb-5'} >
                    <div className={'card-body'}>
                        <h5 className={'mb-3'}>
                            ສະມາຊິກແນະນຳທັງໝົດ ({parseInt(parseInt(affitem?.countLevel1 || 0) + parseInt(affitem?.countLevel2 || 0))} ຄົນ)
                        </h5>
                        {
                            /*
<div className={'mb-3 d-flex align-items-center gap-3'}>
                            <div>
                                เรียงตาม:
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className={'bg-transparent'}>
                                        สมาชิกแนะนำทั้งหมด
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item>สมาชิกแนะนำทั้งหมด</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                            */
                        }
                        


                        {affitem?.memberitems && affitem?.memberitems.map(data => {
                            return <div className={'list d-flex gap-3 justify-content-between align-items-center'}
                                        key={`af_${data.id}`}>
                                <div className={'d-flex gap-3 align-items-center'}>
                                    <img className="img-fluid avatar" src={`${process.env.PUBLIC_URL}/images/avatar/01.png`}
                                         alt={data.name}/>
                                    <div>
                                        <div className={'text-primary'}>
                                            {data.name}
                                        </div>
                                        <div className={'small text-secondary'}>
                                        ເປັນສະມາຊິກແລ້ວ {data.age.months && `${data.age.months} ເດືອນ`} {data.age.days && `${data.age.days} ວັນ`}
                                        </div>
                                    </div>
                                </div>

                                <div className={'text-end ' + colorUserLevel(data.level)}>
                                ສະມາຊິກ<br/> ຂັ້ນທີ {data.level}
                                </div>
                            </div>
                        })}

                    </div>
                </div>}
                {/* wnd member */}

                {/* history */}
                {menu === 'history' && <div className={'card card-green mb-5 history'} data-aos="fade-up">
                    <div className={'card-body'}>
                        <div>
                            <h3 className={'text-center text-primary mb-3'}>
                          ຈຳນວນເງິນທີ່ຖອນໄດ້ : {numberWithCommas(affitem?.sumAll || 0)} (ກີບ)
                        </h3>
                        </div>
                        <div className="affpay">
                        ຖອນລາຍໄດ້ຂັ້ນຕ່ຳ 10,000 ກີບ ລູກຄ້າກົດຄຳວ່າ ຖອນລາຍໄດ້ທັງຫມົດ ເງິນ%ຂອງລູກຄ້າແມ່ນຈະຖືກໂອນເຂົ້າບັນຊີທະນາຄານຂອງລູກຄ້າເລີຍຈ້າວ
                        </div>
                        {
                            parseInt(affitem?.penningdata?.amount) > 0 ? <div className={'d-flex justify-content-center mb-3 small text-primary text-center'}>
                            <div  className={'border-primary w-100 border  px-3 py-2 rounded'}>
                            <span style={{fontSize:20}}>ລໍຖ້າການໂອນເງິນ ຈັກໜ່ອຍຈ້າວ</span>
                            </div></div> : <div className={'d-flex justify-content-center mb-3 small text-primary text-center'}>
                            <div onClick={()=>WithdrawAmount()} className={'border-primary btn btn-primary w-100 border  px-3 py-2 rounded'}>
                            ຖອນລາຍໄດ້ {isLoading === true && <Spinner size="sm" /> }
                            </div></div>
                        }
                        
                        
                        <h5 className={'mb-3'}>
                        ປະຫວັດຖອນລາຍໄດ້
                        </h5>
                        <table className={'table'}>
                            <thead>
                            <tr className={'text-secondary'}>
                                <td>
                                ວັນທີ
                                </td>
                                <td className={'text-end'}>
                                ລາຍໄດ້
                                </td>
                             
                            </tr>
                            </thead>

                            <tbody>
                            {affitem?.historyitems && affitem?.historyitems.map(data => {
                                return <tr key={`h_${data.id}`}>
                                    <td>
                                        {data.dtnow}
                                    </td>
                                    <td className={'text-end text-primary'}>
                                        {data.amount}
                                    </td>
                                  
                                </tr>
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>}
                {/* end history */}
            </div>
        </div>
        <div className="linefixed" onClick={()=>navigate('/contacts')}><img width="150" height="231" src="/images/thaiman.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/thaiman.png 150w, /images/thaiman.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
    </div>);
}

export default Affiliate;
