import {NavLink} from "react-router-dom";
import Logo from "./Logo";

function PageHeader({logo, logoSize, backPath, logOut, goPath, logoutPage}) {
    return (
        <div className={'position-relative mb-4'}>
            {backPath &&
                <NavLink className={'btn-back position-absolute'} to={backPath}>
                    <img className="icon-back" src={`${process.env.PUBLIC_URL}/images/icon/arrow_back.png`} alt={'header'}/>
                </NavLink>
            }

            {
                logOut && <div className={'text-end pt-1'}>
                <a className="btn-logout"><img onClick={logoutPage} className="icon-back" src={`${process.env.PUBLIC_URL}/images/logout.png`} alt={'header'}/></a>
            </div>
            }

            {logo &&
                <div className={'text-center'}>
                    <Logo style={{maxHeight: logoSize ? `${logoSize}px` : "80px"}}/>
                </div>
            }
            
            {
                goPath && <div className={'text-end pt-1'}><NavLink className={'btn-back'} to={goPath}>
                ບິນຫວຍ
            </NavLink></div>
            }

          
             
            

        </div>
    );
}

export default PageHeader;
