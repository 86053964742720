import {Modal} from "react-bootstrap";
import { WEBNAME, VDO } from "../../store/config";

function LoginModal(props) {
    return (
        <Modal {...props} centered className={'login-modal'}>
      
                <div className={'closeloginbtn'} onClick={props.onHide}>X</div>

            <Modal.Body className={'contentmodal py-4'}>
                <img src={`/images/${props.filename}`} width={330} height={240} />
            
{
    /*
<video width={330} height={240} controls autoPlay> 
  <source src={`${VDO.urlall}`} //type="video/mp4" 
  />
</video>
    */
}
            
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
