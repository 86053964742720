import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {useState, useEffect} from "react";
import PageHeader from "../../components/PageHeader";
import { gethuaylast } from '../../store/user'
import CountdownTimer from "./CountdownTimer2";
import { VDO } from "../../store/config";
import VedioMoal from './ModalVDO'
function Stock() {

    const dispatch = useDispatch()
    const { huaylast, isLoading } = useSelector(state=>state.user)
    useEffect(()=> {
        dispatch(gethuaylast())
    },[])
    const [show, setShow] = useState(false)
    const showVDO = () => {
        setShow(true)
       }
   
    const navigate = useNavigate();

    const open = (data) => {
        if (data.statusclose) return;
        return navigate(`/lotto/stock/${data.code}`);
    }

    return (
        <div className={'container hoon-list'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'}>
                    {/* page title */}
                    <PageHeader logo backPath={'/lotto'}/>
                    <div className={'text-center mb-2'}>
                    {
                            VDO.open === 1 &&  <a href={`${VDO.urlall2}`} target="_blank"><div className={'btn btn-danger'} >
                            VDO ຂັ້ນ​ຕອນ​ຊື້ເລກ
                            </div></a>
                        }
                       <div className={'btn btn-danger'} style={{paddingLeft:5, marginLeft:10}} onClick={()=>showVDO()} >
                       ອັດຕາຈ່າຍ
                            </div>
                    </div>
                    {/* end page title */}

                    {/* list */}
                    {
                        isLoading === true ? <div>
                        <div style={{height:80}}></div>
                        <div className="ring">LOADING
                    <span className='span1'></span>
                  </div>
                  <div style={{height:180}}></div>
                 
                  </div> : <div >
                        {huaylast && huaylast.map(mode => {
                            return <div className={'mb-3'} key={`mode_${mode.title}`}>
                                <div className={'fs-5'}> {mode.title} </div>


                                <div className={'row'}>
                                    {mode.items.map(data => {
                                        return <div key={`${data.name}`}
                                                    className={`text-decoration-none col-6 col-md-4 mb-3 ${data.statusclose ? 'lottoclose' : ''}`}
                                                    onClick={() => {
                                                        open(data)
                                                    }}>

                                            <div className={'text-center'}>
                                                <div className={'xribbonlotto'}>
                                                    ປິດຮັບ {data.time}
                                                </div>
                                            </div>
                                            <div className={'card card-green'}>

                                                <div className={`header position-relative ${data.bghead}`}>

                                                    <div className={'text-center'}>
                                                        {data.name}
                                                    </div>
                                                    <div className={`flags position-absolute ${data.bgphoto}`}>

                                                    </div>
                                                </div>

                                                <div className={'card-body'}>
                                                    <div className={'text-center'}>
                                                        {data.open}
                                                    </div>
                                                     <div className={'text-center'}>
                                                    <CountdownTimer countdownTimestampMs={data?.timestamp} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>

                            </div>
                        })}
                    </div>
                    }
                    
                    {/* end list */}
                </div>
            </div>
            <VedioMoal filename='rate1new.jpg' show={show} onHide={()=>setShow(false)}/>
        </div>
    );
}

export default Stock;
