import {NavLink, useNavigate} from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import {useState} from "react";

function Lotto() {
    const navigate = useNavigate()
    const [thai] = useState([
        {
            name: "3ໂຕບົນ",
            rate: "700,000 ກີບ"
        },
        {
            name: "3ໂຕໂຕ໊ດ",
            rate: "100,000 ກີບ"
        },
        {
            name: "2ໂຕບົນ - ລ່າງ",
            rate: "85,000 ກີບ"
        },
        {
            name: "1 ໂຕ",
            rate: "ຈ່າຍ 3 ເທົ່າ"
        },
        {
            name: "1 ໂຕລ່າງ",
            rate: "ຈ່າຍ 4 ເທົ່າ"
        }
    ])
    const [inter] = useState([{
        name: "3ໂຕບົນ",
        rate: "700,000 ກີບ"
    },
        {
            name: "3ໂຕໂຕ໊ດ",
            rate: "100,000 ກີບ"
        },
        {
            name: "2ໂຕບົນ - ລ່າງ",
            rate: "85,000 กีบ"
        },
        {
            name: "1 ໂຕ",
            rate: "ຈ່າຍ 3 ເທົ່າ"
        },
        {
            name: "1 ໂຕລ່າງ",
            rate: "ຈ່າຍ 4 ເທົ່າ"
        }])

    return (
        <div className={'container huay-type'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo/>
                    {/* end page title */}

                    {/* type */}
                    <div className={'d-flex justify-content-around gap-1 mb-3'} data-aos="fade-up">
                        <NavLink to={'/lotto/tunsamai'}>
                            <img alt={'lotto'} className="boxhuay"
                                src={`${process.env.PUBLIC_URL}/images/thai.png`} />
                        </NavLink>

                        <NavLink to={'/lotto/stock'}>
                            <img alt={'lotto'}
                                src={`${process.env.PUBLIC_URL}/images/huay-hoon.png`}/>
                        </NavLink>
                    </div>
                    {/* end type */}

                    {/* rate */}
                    <div className={'card card-green p-3 mb-3'} data-aos="fade-up">
                        <img src={`/images/rate2.png`} />
                        {
                            /*
 <table className={'table table-bordered mb-0'}>
                            <tbody>
                            <tr>
                                <td className={'text-center'} colSpan={2}>
                                ອັດຕາຈ່າຍ <br/>
                                ລັດທະບານໄທ
                                </td>
                            </tr>

                            {thai.map(data => {
                                return <tr key={data.name}>
                                    <td>
                                        {data.name}
                                    </td>
                                    <td className={'text-end'}>
                                        {data.rate}
                                    </td>
                                </tr>
                            })}


                            </tbody>
                        </table>
                            */
                        }
                       
                    </div>

                    <div className={'card card-green p-3 mb-5'} data-aos="fade-up">

                    <img src={`/images/rate1new.jpg`} />
                    {
                        /*
 <table className={'table table-bordered mb-0'}>
                            <tbody>
                            <tr>
                                <td className={'text-center'} colSpan={2}>
                                ອັດຕາຈ່າຍ <br/>
                                ຫວຍຕ່າງປະເທດ ຫວຍຫຸ້ນ
                                </td>
                            </tr>

                            {inter.map(data => {
                                return <tr key={data.name}>
                                    <td>
                                        {data.name}
                                    </td>
                                    <td className={'text-end'}>
                                        {data.rate}
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                        */
                    }
                       
                    </div>
                    {/* end rate */}

                </div>
            </div>
            <div className="linefixed" onClick={()=>navigate('/contacts')}><img width="150" height="231" src="/images/thaiman.png" className="image wp-image-2454  attachment-full size-full" alt="" decoding="async" loading="lazy" srcset="/images/thaiman.png 150w, /images/thaiman.png 32w" sizes="(max-width: 150px) 100vw, 150px" /></div>
        </div>
    );
}

export default Lotto;
